import React, { useEffect } from 'react';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../App/common/data/siteConfig';
import Gallery from '../_features/slideshow/Index';
import ViewDashboard from '../../App/common/icons/ViewDashboard';

export default function SlideShow(props) {
  const { auth, history, slideshows, getSlideshows } = props;

  useEffect(() => {
    getSlideshows(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBodyHeader slideShowsLength={slideshows.length}>
      <SlideshowContent auth={auth} slideshows={slideshows} />
    </CardBodyHeader>
  );
}

function CardBodyHeader({ children, slideShowsLength }) {
  return (
    <div className='column fadein is-12'>
      <div className='card mb-2 ml-2 mr-2'>
        <header className='card-header has-background-white has-text-info hand-pointer'>
          <div className='custom-card-flex is-size-6' style={{ width: '100%' }}>
            <p>
              <span className='flex justify-start align-center'>
                <ViewDashboard size={18} color={'#3f8ed0'} />
                <span className='ml-1'>Dashboard</span>
              </span>
            </p>
          </div>
        </header>
        <div
          className='card-content'
          style={!slideShowsLength ? {} : { padding: 0 }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function SlideshowContent({ slideshows, auth }) {
  let images = [];

  slideshows &&
    slideshows.forEach((item) => {
      let file = item && item.file && JSON.parse(item.file);
      let filelink = file && file.filelink;
      let image = {
        original: `${SITE_ADDRESS_PEMBELAJARAN}${filelink}`,
        originalTitle: item.description,
      };
      images.push(image);
    });

  if (slideshows && slideshows.length < 1)
    return (
      <div className='ml-3 py-2'>
        Selamat datang{' '}
        <span className='is-capitalized has-text-info has-text-weight-semibold'>
          {' '}
          {auth && auth.name ? auth.name?.toLowerCase() : ''}
        </span>
        , di portal Sekolah.
      </div>
    );

  return images && images[0] && <Gallery images={images} />;
}
