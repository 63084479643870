import React from 'react';
const PopUpPdfModalLazyLoad = React.lazy(() =>
  import('./PopUpPdfModalLazyLoad')
);

function PopUpPdfModal(props) {
  return (
    <>
      <PopUpPdfModalLazyLoad {...props} />
    </>
  );
}

export default PopUpPdfModal;
