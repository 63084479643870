import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SideMenu from './Sidemenu/SideMenu';
import LogoPicture from '../../images/logo.webp';
import { sessionLogout, updateAuth } from '../login/redux/authApi';
import { swipeToggleStatus } from '../common/reduxs/swipe/reduxApi';
import { SCHOOL_NAME, SITE_ADDRESS } from '../common/data/siteConfig';
import UserProfile from '../../images/user-default.png';
import BottomNavbar from '../navbar/BottomNavbar';
import { getPesans } from '../../menus/_features/pesan/redux/reduxApi';
import { openModal } from '../modals/redux/modalActions';
import {
  setTesStatus,
  setNavTesStatus,
} from '../../menus/_features/pembelajaran/redux/reduxApi';
import AccountBox from '../common/icons/AccountBox';
import Key from '../common/icons/Key';
import Logout from '../common/icons/Logout';

const mapState = (state, ownProps) => {
  let auth = state.auth;
  let deviceId = auth && auth.deviceId;
  let tesStatus =
    state.pembelajaran.tess && state.pembelajaran.tesStatus
      ? state.pembelajaran.tesStatus
      : {};
  return {
    auth: auth,
    deviceId: deviceId,
    profile: state.sprofil,
    menus: state.menus,
    loading: state.async.loading,
    unreadPesan: state.dashboard.unreadPesan,
    elementName: state.async.elementName,
    swipe: state.swipe,
    tesStatus,
  };
};

const actions = {
  sessionLogout,
  updateAuth,
  getPesans,
  swipeToggleStatus,
  openModal,
  setTesStatus,
  setNavTesStatus,
};

export class Navbar extends Component {
  _isMounted = false;
  state = {
    width: 0,
    isTogglerActive: false,
    inside: false,
    outside: false,
  };

  componentDidMount = (prevProps, prevState) => {
    const { auth, getPesans, history } = this.props;
    this._isMounted = true;
    const bodyElement = document.getElementsByTagName('BODY')[0];
    this.setState({
      width: bodyElement.offsetWidth,
    });

    if (window.addEventListener) {
      window.addEventListener('resize', this.onResizeEvent, true);
    } else {
      if (window.attachEvent) {
        window.attachEvent('onresize', this.onResizeEvent);
      }
    }
    getPesans(auth, history);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    if (window.removeEventListener) {
      window.removeEventListener('resize', this.onResizeEvent, true);
    } else {
      if (window.detachEvent) {
        window.detachEvent('onresize', this.onResizeEvent);
      }
    }
  };

  onResizeEvent = () => {
    const bodyElement = document.getElementsByTagName('BODY')[0];
    const newWidth = bodyElement.offsetWidth;
    if (newWidth !== this.state.width) {
      this.setState({
        width: newWidth,
      });
    }
  };

  handleToggler = () => {
    this.setState({
      isTogglerActive: !this.state.isTogglerActive,
    });
    const burger = document.getElementById('burger');
    if (!this.state.isTogglerActive) {
      burger.classList.add('active');
      burger.classList.remove('not-active');
    } else {
      burger.classList.add('not-active');
      burger.classList.remove('active');
    }
  };

  handleInsideClick = (value) => {
    this.setState({
      inside: value,
    });
  };

  handleTogglerOnOff = (value) => {
    this.setState({
      isTogglerActive: value,
    });
  };

  handleActiveLink = () => {
    const { auth, location } = this.props;
    const regex = new RegExp(`/profile/${auth.userId}`);
    const isActive = regex.test(location.pathname);
    return isActive;
  };

  handleLink = (value) => {
    const { tesStatus } = this.props;
    if (tesStatus && tesStatus.title) return;
    this.props.history.push(value.split('_')[0]);
  };

  handleSessionLogout = (elementName) => {
    const { sessionLogout, history, deviceId } = this.props;
    if (deviceId && deviceId.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    sessionLogout(elementName, history);
  };

  render() {
    const {
      auth,
      deviceId,
      profile,
      menus,
      location,
      history,
      loading,
      elementName,
      updateAuth,
      unreadPesan,
      swipeToggleStatus,
      swipe,
      tesStatus,
      setTesStatus,
      setNavTesStatus,
      openModal,
    } = this.props;
    const { isTogglerActive, width } = this.state;
    let tesMode = tesStatus && tesStatus.title;

    return (
      <>
        {auth.isAuth &&
          location.pathname !== '/register-2' &&
          location.pathname !== '/register-3' && (
            <nav
              id='navbarTop'
              className='navbar is-fixed-top box-shadow-y gradient-navbar'
            >
              <div className='navbar-brand'>
                {auth && auth.isAuth && (
                  <div
                    style={!deviceId ? {} : { display: 'none' }}
                    ref={this.navbarBurger}
                    id='burger'
                    onClick={this.handleToggler}
                    className='navbar-burger toggler not-active is-hidden-tablet'
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                )}
                <div
                  className='navbar-item is-size-5 has-text-weight-semibold hand-pointer2'
                  onClick={() => this.handleLink('/dashboard_')}
                  style={
                    auth && auth.isAuth && width < 769 && !deviceId
                      ? { marginLeft: 38 }
                      : {}
                  }
                >
                  <img
                    alt='profile-pic'
                    src={LogoPicture}
                    style={{ border: '1px solid #ffffff', height: 26, width: 'auto' }}
                  />
                  <span className='ml-1'>{SCHOOL_NAME}</span>
                </div>
              </div>
              {auth.isAuth === true && (
                <div className='navbar-menu'>
                  <div className='navbar-end'>
                    <div
                      className={`navbar-item${
                        tesMode ? ' ' : ' has-dropdown is-hoverable'
                      }`}
                    >
                      <div id='navUser' className='navbar-item hand-pointer'>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            backgroundImage: `url(${
                              auth && auth.mainPhoto
                                ? SITE_ADDRESS + auth.mainPhoto
                                : UserProfile
                            })`,
                            borderRadius: '50%',
                            backgroundSize: 'cover',
                          }}
                        />
                        <span className='has-text-weight-semibold is-size-7 ml-1'>
                          {auth.username && auth.username.length < 4 ? (
                            <span className='is-uppercase'>
                              {auth.name.toLowerCase() ?? auth.username.toLowerCase()}
                            </span>
                          ) : (
                            <span className='is-capitalized'>
                              {auth.name ?? auth.username}
                            </span>
                          )}
                        </span>
                      </div>
                      <div className='navbar-dropdown is-right'>
                        <Link
                          to={`/profil`}
                          className={
                            this.handleActiveLink() === true
                              ? 'navbar-item is-active has-background-white-ter'
                              : 'navbar-item'
                          }
                        >
                          <span className='flex justify-start align-center'>
                            <AccountBox size={16} color={'#000000'} />
                            <span className='ml-1'>Profil</span>
                          </span>
                        </Link>
                        <Link to={`/pengaturan`} className='navbar-item'>
                          <span className='flex justify-start align-center'>
                            <Key size={16} color={'#000000'} />
                            <span className='ml-1'>Ubah Password</span>
                          </span>
                        </Link>
                        <hr className='navbar-divider' />
                        {loading && elementName === 'logoutNav' ? (
                          <button
                            disabled
                            className='button is-loading is-text is-small'
                          >
                            <span className='flex justify-start align-center'>
                              <Logout size={16} color={'#000000'} />
                              <span className='ml-1'>Logout</span>
                            </span>
                          </button>
                        ) : (
                          <Fragment>
                            <div
                              onClick={() =>
                                this.handleSessionLogout('logoutNav')
                              }
                              className='navbar-item hand-pointer'
                            >
                              <span className='flex justify-start align-center'>
                                <Logout size={16} color={'#000000'} />
                                <span className='ml-1'>Logout</span>
                              </span>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </nav>
          )}
        {auth &&
          auth.isAuth &&
          location &&
          location.pathname !== '/register-2' &&
          location.pathname !== '/register-3' && (
            <SideMenu
              toggler={isTogglerActive}
              inside={this.state.inside}
              swipeToggleStatus={swipeToggleStatus}
              swipe={swipe}
              handleInsideClick={this.handleInsideClick}
              handleTogglerOnOff={this.handleTogglerOnOff}
              profile={{ ...profile, mainPhoto: auth.mainPhoto }}
              auth={auth}
              history={history}
              menus={menus}
              logout={this.handleSessionLogout}
              loading={loading}
              elementName={elementName}
              pathname={location.pathname}
              tesStatus={tesStatus}
              tesMode={tesMode}
              setTesStatus={setTesStatus}
              setNavTesStatus={setNavTesStatus}
              openModal={openModal}
            />
          )}
        {deviceId && (
          <BottomNavbar
            profile={{ ...profile, mainPhoto: auth.mainPhoto }}
            unreadPesan={unreadPesan}
            logout={this.handleSessionLogout}
            updateAuth={updateAuth}
            pathname={location.pathname}
            menus={menus}
            auth={auth}
            history={history}
            tesStatus={tesStatus}
            tesMode={tesMode}
            setTesStatus={setTesStatus}
            setNavTesStatus={setNavTesStatus}
            openModal={openModal}
          />
        )}
      </>
    );
  }
}

export default withRouter(connect(mapState, actions)(Navbar));
