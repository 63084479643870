import { ANNOUNCEMENTS_GET } from './reduxConstant';

export const announcementsGet = (announcements) => {
  return {
    type: ANNOUNCEMENTS_GET,
    payload: {
      announcements,
    },
  };
};
