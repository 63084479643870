import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import { slideshowsGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';

export const getSlideshows = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSlideshows' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'portalsetting/slideshow/all',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const slideshows = response.slideshows;
      dispatch(slideshowsGet(slideshows));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
