import CircleMedium from '../common/icons/CircleMedium';

export default function MessageBadge({ unreadPesan }) {
  return (
    <span
      className='is-overlay'
      style={
        unreadPesan && unreadPesan[0]
          ? { display: 'block', position: 'absolute', left: 18, top: -6 }
          : { display: 'none' }
      }
    >
      <CircleMedium size={18} color={'#f24667'} />
    </span>
  );
}
