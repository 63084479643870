import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeModal } from '../../../../App/modals/redux/modalActions';
import { removeSession } from '../../../../App/common/helpers/othersHelpers';
import { setTesStatus } from '../redux/reduxApi';
import TextBoxRemove from '../../../../App/common/icons/TextBoxRemove';
import ShieldHalfFull from '../../../../App/common/icons/ShieldHalfFull';
import MBody from './MBody';
import { dateToLocalTime } from '../../../../App/common/helpers/timeConvert';

function mapState(state, ownProps) {
  let tesStatus = {};
  if (state.pembelajaran.tess && state.pembelajaran.tesStatus) {
    tesStatus = state.pembelajaran.tesStatus;
  }
  return {
    tesStatus,
  };
}
const actions = {
  setTesStatus,
  closeModal,
};

function ModalTesCancel(props) {
  let history = useHistory();
  const { data, closeModal, setTesStatus, tesStatus } = props;
  const auth = data.auth;
  const [counter, setCounter] = useState(9);

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      setCounter((prevState) => {
        return prevState - 1;
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  const onClose = () => {
    closeModal();
  };

  const onTesCancel = () => {
    let tesId = tesStatus && tesStatus.tesId;
    if (tesId) {
      removeSession(tesId);
      removeSession('questions-' + tesId);
      removeSession('time-' + tesId);
    }
    let activity = tesStatus && tesStatus.activity;
    let questionLength = activity.split('/')[1];
    let value = {
      id: tesStatus.id,
      tesId: tesStatus.tesId,
      tesStartStatus: false,
      unfocus: [],
      activity: `0/${questionLength}`,
      lastSeen: dateToLocalTime(new Date()),
    };
    setTesStatus(auth, history, value, true);
    closeModal();
    history.push('/pembelajaran/tes/' + tesId);
  };

  return (
    <MBody>
      <p>
        <TextBoxRemove size={64} color={'#f24667'} />
      </p>
      <p className='mt-2'>
        <span className='tag is-danger is-medium is-rounded'>
          <ShieldHalfFull size={15} color={'#ffffff'} />
          <span className='ml-1'>Konfirmasi Pembatalan Tes</span>
        </span>
      </p>
      <p className='mt-1'>Apakah kamu ingin membatalkan tes?</p>{' '}
      <p className='is-size-7 is-italic' style={{ width: 300, margin: 'auto' }}>
        Note: Jika memilih batal, tes akan dibatalkan dan jawaban akan direset.
      </p>
      <div
        className='mt-4'
        style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
      >
        <button
          onClick={onTesCancel}
          disabled={counter > 0}
          className='button is-danger is-small is-rounded is-outlined'
        >
          {counter > 0 ? `Batal (${counter})` : 'Batal'}
        </button>
        <button
          onClick={onClose}
          className='button custom-grey is-small is-rounded is-outlined'
        >
          Kembali
        </button>
      </div>
    </MBody>
  );
}

export default connect(mapState, actions)(ModalTesCancel);
