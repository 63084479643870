import React from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import { registerAuth } from './redux/authApi';
import LoginTextInput from '../common/form/LoginTextInput';
import BackToLogin from './part/BackToLogin';
import StepActive from './part/StepActive';
import BodyAuth from './part/BodyAuth';
import { useWindowSize } from '../common/helpers/sizeHelper';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  registerAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username siswa harus diisi' }),
  password: isRequired({ message: 'Password siswa harus diisi' }),
});

function Register1({ registerAuth, handleSubmit, auth, error, loading }) {
  const history = useHistory();

  const handleLogin = (authData) => {
    registerAuth(history, authData);
  };

  let windowSize = useWindowSize();

  const handleBackToLogin = () => {
    return history.push('/');
  };

  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;
  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;

  return (
    <BodyAuth
      title={'Register Orang Tua/Wali di Portal Sekolah'}
      width={
        windowSize[0] < 450 ? (windowSize[0] > 365 ? 365 : windowSize[0]) : 450
      }
    >
      <StepActive active={1} />
      <form onSubmit={handleSubmit(handleLogin)} autoComplete='off'>
        <Field
          name='username'
          type='text'
          component={LoginTextInput}
          placeholder='Username'
          autoComplete='new-password'
          label='Username Siswa'
          icon='account'
        />
        <Field
          name='password'
          type='password'
          autoComplete='new-password'
          component={LoginTextInput}
          placeholder='Password'
          label='Password Siswa'
          icon='lock'
        />
        <div className='field has-text-centered' style={{ marginTop: 25 }}>
          {error && <p className='help is-danger'>{error}</p>}
          <button
            type='submit'
            disabled={loading}
            style={{ marginRight: 10 }}
            className={`button is-info is-small is-rounded${
              loading ? ' is-loading' : ''
            }`}
          >
            Selanjutnya
          </button>
        </div>
      </form>
      <BackToLogin inBackToLogin={handleBackToLogin} />
    </BodyAuth>
  );
}

export default withRouter(
  connect(
    mapState,
    actions
  )(reduxForm({ form: 'formRegister1', validate })(Register1))
);
