export const objectToArray = (object) => {
  if (object) {
    return Object.entries(object).map((e) => Object.assign({}, e[1]));
  }
};
// get array value from array key
export const arrKeyValue = (arrMain, arrFilter, compare, result) => {
  let setArr = [];
  arrMain.forEach((main) => {
    for (let i = 0; i < arrFilter.length; i++) {
      if (main[compare] + '' === arrFilter[i] + '') {
        setArr.push(main[result]);
      }
    }
  });
  return setArr;
};
// Replace id with key of the object => for firestore
export const objectToArrayFirestore = (object) => {
  if (object) {
    return Object.entries(object).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
};

export const sortArrayObject = (data, key, sort) => {
  if (sort === 'asc') {
    return data.sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
  } else {
    return data.sort((a, b) =>
      a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
    );
  }
};

export const randomArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};
