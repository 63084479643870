import { DASHBOARD_GET, UNREAD_PESAN_GET, UNREAD_PESAN_UPDATE } from "./reduxConstant";
import { createReducer } from "../../../App/common/utilities/reducerUtils";

const initialState = {
  dashboard: {},
  unreadPesan: []
};

const dashboardGet = (state, payload) => {
  return { ...state, ...payload.dashboard };
};

const unreadPesanGet = (state, payload) => {
  return {
    ...state,
    unreadPesan: payload.unreadPesan,
  }
}

const unreadPesanUpdate = (state, payload) => {
  let unreadPesan = [];
  if (state.unreadPesan && state.unreadPesan[0]) {
    unreadPesan = state.unreadPesan.filter((pesan) => pesan.messageId + '' !== payload.unreadPesan.messageId + '');
  }
  return {
    ...state,
    unreadPesan: unreadPesan,
  }
}

export default createReducer(initialState, {
  [DASHBOARD_GET]: dashboardGet,
  [UNREAD_PESAN_GET]: unreadPesanGet,
  [UNREAD_PESAN_UPDATE]: unreadPesanUpdate,
});
