import React, { useEffect } from 'react';
import { withRouter, Redirect, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionLogout, getRoleAuth } from './redux/authApi';
import { relationWithStudent } from '../common/helpers/optionHelpers';
import CheckCircle from '../common/icons/CheckCircle';
import AccountPlus from '../common/icons/AccountPlus';
import LoginIcon from '../common/icons/LoginIcon';
import BodyAuth from './part/BodyAuth';
import { useWindowSize } from '../common/helpers/sizeHelper';

function mapState(state) {
  let dataOptionRelation = [];
  let auth = state && state.auth;
  if (auth.role && auth.role.detail) {
    let roleDetail = auth.role.detail;
    let filterData = relationWithStudent;
    if (roleDetail.length > 0) {
      for (let i = 0; i < roleDetail.length; i++) {
        let data = filterData.filter((item) => {
          return item.value !== roleDetail[i].ROLE;
        });
        filterData = data;
      }
      dataOptionRelation = filterData;
    } else {
      dataOptionRelation = filterData;
    }
  }
  return {
    auth,
    loading: state.async.loading,
    dataOptionRelation: dataOptionRelation,
  };
}

const actions = {
  sessionLogout,
  getRoleAuth,
};

function Register3(props) {
  const { sessionLogout, auth, loading, getRoleAuth, dataOptionRelation } =
    props;
  let history = useHistory();

  let windowSize = useWindowSize();

  useEffect(() => {
    getRoleAuth(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleAuth]);

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  if (auth && !auth.isAuth) return <Redirect to='/register' />;
  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;

  return (
    <BodyAuth title={'Register Orang Tua/Wali di Portal Sekolah'}
    width={
      windowSize[0] < 450 ? (windowSize[0] > 365 ? 365 : windowSize[0]) : 450
    }
  >
      {dataOptionRelation && dataOptionRelation.length !== 0 && (
        <>
          <div id='fadein2' className='has-text-centered mt-3 mb-3'>
            <CheckCircle size={70} color={'#00d1b2'} />
          </div>
          <div className='is-size-6 has-text-centered is-italic mt-2'>
            User telah berhasil dibuat.
          </div>
          <div className='has-text-link is-size-5 has-text-centered is-italic mt-5'>
            <Link
              className='button is-link is-small is-rounded mr-4'
              to='/register-2'
            >
              <AccountPlus size={12} color={'#ffffff'} />
              <span className='ml-1'>User</span>
            </Link>
            <div
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={() => handleSessionLogout('logoutNav')}
            >
              <LoginIcon size={12} color={'#363636'} />
              <span className='ml-1'>Login</span>
            </div>
            <br />
            <br />
          </div>
        </>
      )}
      {dataOptionRelation && dataOptionRelation.length === 0 && !loading && (
        <>
          <div id='fadein2' className='has-text-centered mt-3 mb-3'>
            <CheckCircle size={70} color={'#00d1b2'} />
          </div>
          <div className='is-size-6 has-text-centered is-italic mt-2'>
            Semua user orang tua wali telah dibuat.
          </div>
          <div className='has-text-link is-size-5 has-text-centered is-italic mt-3'>
            <div
              className='button is-link is-small is-rounded is-outlined'
              onClick={() => handleSessionLogout('logoutNav')}
            >
              <LoginIcon size={12} color={'#363636'} />
              <span className='ml-1'>Login</span>
            </div>
            <br />
            <br />
          </div>
        </>
      )}
    </BodyAuth>
  );
}

export default withRouter(connect(mapState, actions)(Register3));
