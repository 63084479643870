import { RAPORPROJEK_GET, RAPORPROJEKDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = { raporprojek: [] };

const raporprojekGet = (state, payload) => {
  let raporprojek = payload.raporprojek;
  if (!raporprojek) return state;
  return { ...state, raporprojek: [...raporprojek] };
};

const raporprojekDetailGet = (state, payload) => {
  if (!payload.raporprojekDetail) return state;
  let raporprojekDetail = {
    ...state.raporprojek.filter(
      (item) => item.IDXRAPOR === payload.raporprojekDetail.id
    )[0],
    ...payload.raporprojekDetail,
  };
  return {
    ...state,
    raporprojek: [
      ...state.raporprojek.filter(
        (item) => item.IDXRAPOR !== payload.raporprojekDetail.id
      ),
      raporprojekDetail,
    ],
  };
};

export default createReducer(initialState, {
  [RAPORPROJEK_GET]: raporprojekGet,
  [RAPORPROJEKDETAIL_GET]: raporprojekDetailGet,
});
