import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { swipeToggleStatus } from '../../App/common/reduxs/swipe/reduxApi';
import { SWIPE_STYLE } from '../../App/common/styles/swipe_style';
import { getRoleAuth } from '../../App/login/redux/authApi';
import { getProfil } from '../profile/redux/reduxApi';
import {
  getAnnouncements,
  downloadFile,
} from '../_features/announcement/redux/reduxApi';
import {
  getTingkats,
  setReadActivity,
} from '../_features/pembelajaran/redux/reduxApi';
import { getPembelajaran } from './redux/reduxApi';
import { getSlideshows } from '../_features/slideshow/redux/reduxApi';
import AnnouncementWidget from './AnnouncementWidget';
import SlideShowWidget from './SlideShowWidget';
import PembelajaranWidget from './PembelajaranWidget';
import { openModal } from '../../App/modals/redux/modalActions';
import { getUnreadPesan } from './redux/reduxApi';

function mapState(state) {
  let scope = {};
  let auth = state.auth;
  let deviceId = auth && auth.deviceId;
  if (deviceId) auth.name = auth.username;
  if (auth && auth.authorities) {
    scope = auth.authorities;
  }
  let pembelajaran = state.pembelajaran;
  const materis = pembelajaran && pembelajaran.materis;
  const tugass = pembelajaran && pembelajaran.tugass;
  const latihans = pembelajaran && pembelajaran.latihans;
  const tess = pembelajaran && pembelajaran.tess;
  let tingkats = pembelajaran?.tingkats;
  let kelass = pembelajaran?.kelass;
  let total = pembelajaran?.total;
  return {
    auth,
    deviceId,
    role: state.userrole,
    slideshows: state.slideshows,
    announcements: state.announcements,
    materis,
    tugass,
    latihans,
    tess,
    tingkats,
    kelass,
    total,
    scope: scope,
    loading: state.async.loading,
    swipe: state.swipe,
  };
}

const actions = {
  openModal,
  swipeToggleStatus,
  getRoleAuth,
  getProfil,
  getAnnouncements,
  getTingkats,
  setReadActivity,
  getPembelajaran,
  getSlideshows,
  getUnreadPesan,
  downloadFile,
};

function Index(props) {
  let history = useHistory();
  const {
    auth,
    loading,
    openModal,
    swipeToggleStatus,
    getRoleAuth,
    getProfil,
    getAnnouncements,
    downloadFile,
    deviceId,
    getTingkats,
    setReadActivity,
    getPembelajaran,
    announcements,
    materis,
    tugass,
    latihans,
    tess,
    tingkats,
    kelass,
    total,
    getSlideshows,
    getUnreadPesan,
    slideshows,
  } = props;

  // useEffect(() => {
  //   const logChange = () => {};
  //   document.addEventListener('visibilitychange', logChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', logChange);
  //   };
  // }, []);

  useEffect(() => {
    if (deviceId) {
      window.ReactNativeWebView.postMessage('dash-loaded');
    }
  }, [deviceId]);

  useEffect(() => {
    getRoleAuth(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleAuth]);

  useEffect(() => {
    getTingkats(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = useCallback(
    (url, id, title, content) => {
      history.push(url);
      if (id) {
        setReadActivity(auth, history, id, title, content);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setReadActivity]
  );

  return (
    <>
      <SwipeHandler swipeToggleStatus={swipeToggleStatus} />
      <BodyCard auth={auth}>
        <SlideShowWidget
          auth={auth}
          history={history}
          loading={loading}
          slideshows={slideshows}
          getSlideshows={getSlideshows}
          handleLink={handleLink}
        />
        <AnnouncementWidget
          auth={auth}
          history={history}
          openModal={openModal}
          announcements={announcements}
          getAnnouncements={getAnnouncements}
          handleLink={handleLink}
          downloadFile={downloadFile}
        />
        <PembelajaranWidget
          auth={auth}
          history={history}
          loading={loading}
          materis={materis}
          tugass={tugass}
          latihans={latihans}
          tess={tess}
          tingkats={tingkats}
          kelass={kelass}
          total={total}
          getPembelajaran={getPembelajaran}
          handleLink={handleLink}
        />
        <AuthContent auth={auth} history={history} getProfil={getProfil} getUnreadPesan={getUnreadPesan} />
      </BodyCard>
    </>
  );
}

function BodyCard({ children, auth }) {
  if (auth.isAuth !== true) return <Redirect to='/login' />;
  if (auth.isAuth === true && auth.registerParents)
    return <Redirect to='/register-2' />;

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1d'>
        <div className='columns is-gapless is-multiline is-variable is-desktop'>
          {children}
        </div>
      </div>
    </div>
  );
}
function SwipeHandler({ swipeToggleStatus }) {
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => {
      swipeToggleStatus(true);
    },
  });
  return <div {...handlers} style={SWIPE_STYLE} />;
}

function AuthContent({auth, history, getProfil, getUnreadPesan}) {

  useEffect(() => {
    getProfil(auth, history);
    getUnreadPesan(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<></>)
}

export default connect(mapState, actions)(Index);
