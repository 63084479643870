import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function LoadingPage() {
  const [state, setState] = useState({
    start: 0,
  });
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setState({
        start: 1,
      });
    }, 100);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable is-desktop'>
          <div className='column is-12'>
            <div className='card'>
              <header className='card-header'>
                <div className='ml-4 pt-1 mr-4 pb-1' style={{width: 160}}>
                  <Skeleton />
                </div>
              </header>
              {state && state.start === 1 && (
                <div className='card-content'>
                  <div className='content'>
                    <div className='table-container ml-3 mr-3'>
                      <Skeleton count={3} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
