import React from 'react';
import ViewDashboard from './ViewDashboard';
import AccountBox from './AccountBox';
import CalendarMonth from './CalendarMonth';
import AccountCog from './AccountCog';
import FormatListChecks from './FormatListChecks';
import ClockTimeSevenOutline from './ClockTimeSevenOutline';
import Bookshelf from './Bookshelf';
import FileDocument from './FileDocument';
import FileChart from './FileChart';
import FileCompare from './FileCompare';
import Bullhorn from './Bullhorn';
import MessageTextOutline from './MessageTextOutline';

const Icons = ({ size, color, icon }) => {
  if (icon === 'view-dashboard')
    return <ViewDashboard size={size} color={color} />;

  if (icon === 'account-box')
    return <AccountBox size={size} color={color} />;

  if (icon === 'format-list-checks')
    return <FormatListChecks size={size} color={color} />;

  if (icon === 'clock-time-seven-outline')
    return <ClockTimeSevenOutline size={size} color={color} />;

  if (icon === 'bookshelf')
    return <Bookshelf size={size} color={color} />;

  if (icon === 'file-document')
    return <FileDocument size={size} color={color} />;

  if (icon === 'file-chart')
    return <FileChart size={size} color={color} />;

  if (icon === 'file-compare')
    return <FileCompare size={size} color={color} />;

  if (icon === 'bullhorn') return <Bullhorn size={size} color={color} />;

  if (icon === 'calendar-month')
    return <CalendarMonth size={size} color={color} />;

  if (icon === 'message-text-outline')
    return <MessageTextOutline size={size} color={color} />;

  if (icon === 'account-cog')
    return <AccountCog size={size} color={color} />;
  return (
    <svg
      width={size}
      height={size}
      color={color}
      viewBox='0 0 24 24'
    >
      <path d='M10,17L15,12L10,7V17Z' />
    </svg>
  );
};

export default Icons;
