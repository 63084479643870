import React from 'react';
import { connect } from 'react-redux';
import NotAuthorizedModal from './NotAuthorizedModal';
import MenuModal from '../navbar/MenuModal';
import ImageModal from '../common/layout/ImageModal';
import DeleteAccountModal from '../../menus/_features/pengaturan-akun/DeleteAccountModal';
import PopUpImageModal from '../common/layout/PopUpImageModal';
import PopUpPdfModal from '../common/layout/PopUpPdfModal';
import TugasFileDelete from '../../menus/_features/pembelajaran/tugas/DeleteFile';
import TesLeavePage from '../../menus/_features/pembelajaran/tes/MTesLeavePage';
import TesCancel from '../../menus/_features/pembelajaran/tes/MTesCancel';
import TesSubmit from '../../menus/_features/pembelajaran/tes/MTesSubmit';

const modalLookup = {
  NotAuthorizedModal,
  MenuModal,
  PopUpImageModal,
  ImageModal,
  PopUpPdfModal,
  TugasFileDelete,
  TesLeavePage,
  TesCancel,
  TesSubmit,
  DeleteAccountModal,
};

const mapState = (state) => ({
  currentModal: state.modals,
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
