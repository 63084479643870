import React, { useState } from 'react';
import { connect } from 'react-redux';
import { deleteFile } from '../redux/reduxApi';
import { closeModal } from '../../../../App/modals/redux/modalActions';
import ShieldHalfFull from '../../../../App/common/icons/ShieldHalfFull';
import Delete from '../../../../App/common/icons/Delete';
import ArrowLeft from '../../../../App/common/icons/ArrowLeft';

const actions = {
  closeModal,
  deleteFile,
};

function DeleteFile({ data, deleteFile, closeModal }) {
  const item = data.file;
  const [deleteColor, setDeleteColor] = useState('#ff3860');
  const onDelete = () => {
    deleteFile(data.auth, data.history, data.tugas, data.file);
    closeModal();
  };
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <div className='modal-card-title has-text-danger has-text-weight-normal'>
            <ShieldHalfFull size={20} color={'#ff3860'} />
            <span className='ml-1'>Konfirmasi Hapus</span>
          </div>
          <button
            onClick={closeModal}
            className='delete'
            aria-label='close'
          ></button>
        </header>
        <section className='modal-card-body is-size-6'>
          <p className='my-3 mx-1'>
            Apakah Anda yakin ingin menghapus file{' '}
            <span className='has-text-danger has-text-weight-semibold'>{`${item.filename}`}</span>{' '}
            ?
          </p>
        </section>
        <footer className='modal-card-foot'>
          <button
            onClick={() => onDelete()}
            className='button is-danger is-small is-rounded is-outlined'
            onMouseEnter={() => setDeleteColor('#ffffff')}
            onMouseLeave={() => setDeleteColor('#ff3860')}
          >
            <Delete size={15} color={deleteColor} />
          </button>
          <button
            className='button custom-grey is-small is-rounded is-outlined'
            onClick={closeModal}
          >
            <span className='icon is-small'>
              <ArrowLeft size={15} color={'#383838'} />
            </span>
          </button>
        </footer>
      </div>
    </div>
  );
}

export default connect(null, actions)(DeleteFile);
