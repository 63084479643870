import {
  asyncActionFinish,
  asyncActionError,
} from '../../../App/common/reduxs/async/asyncActions';
import { profilGet, profilUpdate } from './reduxAction';
import { mainPhotoUpdate } from '../../../App/login/redux/authAction';
import { ASYNC_ACTION_START } from '../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS } from '../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../App/common/helpers/checkRes';
import { authUpdate } from '../../../App/login/redux/authAction';

export const getProfil = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProfil' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'profil/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const data = response.profil;
      const urlPhoto = response.urlPhoto;
      const dataProfil = data && data.profil && data.profil[0];
      let newProfil = {
        nis: dataProfil && dataProfil.NIS,
        nisn: dataProfil && dataProfil.NISN,
        name: dataProfil && dataProfil.NAMA,
        address: dataProfil && dataProfil.ALAMAT,
        place_birth: dataProfil && dataProfil.TEMPAT_LAHIR,
        date_birth: dataProfil && dataProfil.TANGGAL_LAHIR,
        phone: dataProfil && dataProfil.TELEPON,
        student_history: [],
      };

      let sortStudentHistory = [];
      for (let i = 0; i < data.history.length; i++) {
        let dataHistory = data.history[i];
        let dataObj = {
          ...dataHistory,
          id: (dataHistory.TAHUN_AJARAN + dataHistory.SEM).split(' ').join(''),
        };
        sortStudentHistory.push(dataObj);
      }
      newProfil = {
        ...newProfil,
        urlPhoto: urlPhoto,
        student_history: sortStudentHistory.sort((a, b) =>
          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        ),
      };
      dispatch(profilGet(newProfil));
      dispatch(authUpdate({ mainPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const photoUpload = (data) => {
  return async (dispatch) => {
    console.log('photo upload');
    dispatch({ type: ASYNC_ACTION_START, payload: 'photoUpload' });
    const sessionId = data.authData.sessionId;
    const userId = data.authData.userId;
    const username = data.authData.username;
    const image = data.image;
    const filename = data.filename;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username.toLowerCase().split(' ').join('_'));
    formData.append('image', image);
    formData.append('filename', filename);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS + 'profil/upload-photo/' + userId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + data.authData.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(
        response,
        fetchData,
        dispatch,
        200,
        data.history
      );
      if (resultCheck) {
        throw resultCheck;
      }
      const urlPhoto = response.urlPhoto;
      dispatch(profilUpdate({
        urlPhoto: urlPhoto,
      }));
      dispatch(mainPhotoUpdate({mainPhoto: urlPhoto}));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
