import { SWIPE_TOGGLE } from './reduxConstant';

export const swipeToggle = (status) => {
  return {
    type: SWIPE_TOGGLE,
    payload: {
      status,
    },
  };
};
