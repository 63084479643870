import React from 'react';

export default function StepActive({ active }) {
  return (
    <div
      className='tags has-addons has-text-centered is-fullwidth flex justify-center'
      style={{
        marginTop: 16,
      }}
    >
      <span className={`tag is-rounded${active === 1 ? ' is-primary' : ''}`}>
        Langkah 1
      </span>
      <span className={`tag is-rounded${active === 2 ? ' is-primary' : ''}`}>
        Langkah 2
      </span>
    </div>
  );
}
