import { PROFIL_GET, PROFIL_UPDATE } from './reduxConstant';

export const profilGet = (profil) => {
  return {
    type: PROFIL_GET,
    payload: {
      profil,
    },
  };
};

export const profilUpdate = (profil) => {
  return {
    type: PROFIL_UPDATE,
    payload: {
      profil,
    },
  };
};
