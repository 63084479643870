import React, { useRef, useEffect } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import { sessionLogout, registerUserRole, getRoleAuth } from './redux/authApi';
import LoginTextInput from '../common/form/LoginTextInput';
import LoginSelectInput from '../common/form/LoginSelectInput';
import CheckCircle from '../common/icons/CheckCircle';
import BackToLogin from './part/BackToLogin';
import StepActive from './part/StepActive';
import BodyAuth from './part/BodyAuth';
import SectionBody from './part/SectionBody';
import Herobody from './part/Herobody';
import LoadingBody from './part/LoadingBody';
import { useWindowSize } from '../common/helpers/sizeHelper';

function mapState(state) {
  let auth = state.auth;
  let dataOptionRelation = auth && auth.dataOptionRelation;
  return {
    auth,
    loading: state.async.loading,
    dataOptionRelation,
  };
}

const actions = {
  sessionLogout,
  registerUserRole,
  getRoleAuth,
};

const validate = combineValidators({
  vusnreg: isRequired({ message: 'Username orang tua/wali harus diisi' }),
  passwordreg: isRequired({ message: 'Password harus diisi' }),
  rolereg: isRequired({ message: 'Hubungan dengan siswa harus diisi' }),
});

function Register2(props) {
  const {
    sessionLogout,
    registerUserRole,
    getRoleAuth,
    handleSubmit,
    auth,
    error,
    loading,
    dataOptionRelation,
  } = props;
  let history = useHistory();
  const relationData = useRef([]);

  let windowSize = useWindowSize();

  useEffect(() => {
    getRoleAuth(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleAuth]);

  const handleRegister = async (creds) => {
    let filterData = relationData.current;
    await registerUserRole(auth, history, creds);
    relationData.current = filterData.filter((item) => {
      return item.value !== creds.rolereg;
    });
  };

  const handleSessionLogout = () => {
    sessionLogout('logoutNav', history);
  };

  if (auth && !auth.isAuth) return <Redirect to='/register' />;
  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;

  if (loading) return <LoadingBody color={'#e1e6f6'} />;

  if (dataOptionRelation && dataOptionRelation.length === 0)
    <SectionBody>
      <Herobody>
        <div id='fadein2' className='has-text-centered mt-3 mb-3'>
          <CheckCircle size={70} color={'#00d1b2'} />
        </div>
        <div className='is-size-6 has-text-centered is-italic mt-2'>
          Semua user orang tua wali telah dibuat.
        </div>
        <BackToLogin inBackToLogin={handleSessionLogout} />
      </Herobody>
    </SectionBody>;

  return (
    <BodyAuth
    title={'Register Orang Tua/Wali di Portal Sekolah'}
      width={
        windowSize[0] < 450 ? (windowSize[0] > 365 ? 365 : windowSize[0]) : 450
      }
    >
      {dataOptionRelation && dataOptionRelation.length !== 0 && (
        <>
          <StepActive active={2} />
          <form onSubmit={handleSubmit(handleRegister)} autoComplete='off'>
            <Field
              name='rolereg'
              type='text'
              autoComplete='new-password'
              component={LoginSelectInput}
              placeholder='hubungan dengan siswa'
              label={`Hubungan dengan ${auth.username}`}
              icon='account-group'
              fullwidth={true}
              options={dataOptionRelation}
            />
            <Field
              name='vusnreg'
              type='text'
              autoComplete='new-password'
              component={LoginTextInput}
              placeholder='ex: user@gmail.com'
              label='Username Orang Tua/Wali'
              icon='account'
            />
            <Field
              name='passwordreg'
              type='password'
              autoComplete='new-password'
              component={LoginTextInput}
              placeholder='Password'
              label='Password'
              icon='lock'
            />
            <div className='field has-text-centered' style={{ marginTop: 30 }}>
              {error && <p className='help is-danger'>{error}</p>}
              <button
                type='submit'
                disabled={loading}
                style={{ marginRight: 10 }}
                className={`button is-info is-small is-rounded is-outlined${
                  loading ? ' is-loading' : ''
                }`}
              >
                Register
              </button>
            </div>
          </form>
          <BackToLogin inBackToLogin={handleSessionLogout} />
        </>
      )}
    </BodyAuth>
  );
}

export default withRouter(
  connect(
    mapState,
    actions
  )(reduxForm({ form: 'formRegister2', validate })(Register2))
);
