export const MATERIS_GET = 'MATERIS_GET';
export const TUGASS_GET = 'TUGASS_GET';
export const STUDENTTUGASS_GET = 'STUDENTTUGASS_GET';
export const TUGAS_UPLOADSTATUS = 'TUGAS_UPLOADSTATUS';
export const LATIHANS_GET = 'LATIHANS_GET';
export const TESS_GET = 'TESS_GET';
export const STUDENTTESS_GET = 'STUDENTTESS_GET';
export const TINGKATS_GET = 'TINGKATS_GET';
export const KELASS_GET = 'KELASS_GET';
export const MATERI_GET = 'MATERI_GET';
export const TUGAS_GET = 'TUGAS_GET';
export const LATIHAN_GET = 'LATIHAN_GET';
export const TES_GET = 'TES_GET';
export const TES_REMARKS_GET = 'TES_REMARKS_GET';
export const NAVTES_STATUS = 'NAVTES_STATUS';
export const LATIHAN_ANSWER_GET = 'LATIHAN_ANSWER_GET';
export const STUDENT_ACTIVITY_GET = 'STUDENT_ACTIVITY_GET';