import React from 'react';

export default function LoginIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      viewBox='0 0 24 24'
    >
      <path d='M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7M20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z' />
    </svg>
  );
}
