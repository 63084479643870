import React from 'react';

export default function ContainerBody({ children, width }) {
  return (
    <div className='container'>
      <div className='columns'>
        <div className='column'></div>
        <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd flex justify-center align-center'>
          <div className='box' style={{ minWidth: width || 365, zIndex: 999 }}>
            {children}
          </div>
        </div>
        <div className='column'></div>
      </div>
    </div>
  );
}
