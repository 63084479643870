import {
  MATERIS_GET,
  TUGASS_GET,
  STUDENTTUGASS_GET,
  LATIHANS_GET,
  TESS_GET,
  STUDENTTESS_GET,
  TINGKATS_GET,
  KELASS_GET,
  MATERI_GET,
  TUGAS_GET,
  TUGAS_UPLOADSTATUS,
  LATIHAN_GET,
  TES_GET,
  TES_REMARKS_GET,
  NAVTES_STATUS,
  LATIHAN_ANSWER_GET,
  STUDENT_ACTIVITY_GET,
} from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';
import { sortArrayObject } from '../../../../App/common/helpers/objectHelpers';
import { randomArray } from '../../../../App/common/helpers/objectHelpers';
import { addMinutes, compareAsc, parseISO } from 'date-fns';

const initialState = {
  total: { materi: 0, tugas: 0, latihan: 0, tes: 0 },
  search: { materi: '', tugas: '', latihan: '', tes: '' },
  materis: [],
  tugass: [],
  tugasStAns: [],
  studenttugass: [],
  latihans: [],
  tess: [],
  studenttess: [],
  tesStatus: {},
  tingkats: [],
  kelass: [],
  latihanAnswer: [],
  studentActivity: {
    materi: { read: [], unread: [] },
    tugas: { read: [], unread: [] },
    latihan: { read: [], unread: [] },
  },
  uploadStatus: 'finish',
};

const materisGet = (state, payload) => {
  let total = { ...state?.total, materi: payload?.materis?.total };
  let items = payload?.materis?.items;
  let sortItems = items && sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    total: total,
    materis: [...sortItems],
  };
};

const tugassGet = (state, payload) => {
  let total = { ...state?.total, tugas: payload?.tugass?.total };
  let filterTugass = [];
  for (let i = 0; i < payload?.tugass?.items.length; i++) {
    const tgs = payload?.tugass?.items[i];
    let tgsSc =
      tgs &&
      tgs.scores &&
      JSON.parse(tgs.scores)?.find(
        (sc) => sc.nis + '' === payload?.tugass?.nis
      );
    let updateTugas = { ...tgs };
    if (tgsSc?.published) {
      updateTugas.scores = tgsSc?.finalScore || 0;
      updateTugas.published = tgsSc?.published || undefined;
    } else {
      updateTugas.scores = undefined;
    }
    filterTugass = [...filterTugass, updateTugas];
  }
  return {
    ...state,
    total: total,
    tugass: filterTugass,
  };
};

const studenttugassGet = (state, payload) => {
  let studenttugass = payload?.studenttugass?.items;
  return {
    ...state,
    studenttugass: [...studenttugass],
  };
};

const latihansGet = (state, payload) => {
  let total = { ...state?.total, latihan: payload?.latihans?.total };
  return {
    ...state,
    total: total,
    latihans: [...payload?.latihans?.items],
  };
};

const tessGet = (state, payload) => {
  let total = { ...state?.total, tes: payload?.tess?.total };
  let filterTess = [];
  for (let i = 0; i < payload?.tess?.items.length; i++) {
    const tes = payload?.tess?.items[i];
    let tesSc =
      tes &&
      tes.scores &&
      JSON.parse(tes.scores)?.find((sc) => sc.nis + '' === payload?.tess?.nis);
    let updateTes = { ...tes };
    if (tesSc?.published) {
      updateTes.scores = tesSc?.finalScore || 0;
      updateTes.published = tesSc?.published || undefined;
    } else {
      updateTes.scores = undefined;
    }
    filterTess = [...filterTess, updateTes];
  }
  return {
    ...state,
    total: total,
    tess: filterTess,
  };
};

const studenttessGet = (state, payload) => {
  let studenttess = payload?.studenttess?.items;
  return {
    ...state,
    studenttess: [...studenttess],
  };
};

const tingkatsGet = (state, payload) => {
  return {
    ...state,
    tingkats: [...payload?.tingkats],
  };
};

const kelassGet = (state, payload) => {
  return {
    ...state,
    kelass: [...payload?.kelass],
  };
};

const materiGet = (state, payload) => {
  let items = [
    ...state?.materis?.filter((item) => item.id !== payload?.materi?.id),
    payload?.materi,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    materis: [...sortItems],
  };
};

const tugasGet = (state, payload) => {
  let item = payload.tugas && payload.tugas.item;
  let questions = item && item.questions && JSON.parse(item.questions);
  let answers = item && item.answers && JSON.parse(item.answers);

  let saveIndex = [];
  let randomQs = [];
  questions &&
    randomArray(questions).forEach((item, index) => {
      saveIndex.push(item.no);
      randomQs.push({ ...item, no: index + 1 });
    });
  let updateItem = {
    ...item,
    questions: randomQs,
    keyAnswers: answers,
    saveIndex: saveIndex,
  };
  let checkScores = item && item.scores && JSON.parse(item.scores);
  let getScore = checkScores?.find(
    (sc) => sc.nis + '' === payload.tugas?.nis + ''
  );
  let updateTugas = {
    ...updateItem,
    scores: getScore?.finalScore || 0,
    published: getScore?.published || undefined,
  };
  if (getScore?.published) {
    updateTugas.scores = getScore.finalScore || 0;
    updateTugas.detailScore = getScore || {};
    updateTugas.published = true;
  } else {
    updateTugas.scores = undefined;
    updateTugas.detailScore = undefined;
  }
  let items = [...state?.tugass?.filter((i) => i.id !== item.id), updateTugas];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tugass: [...sortItems],
  };
};

const tugasUploadStatus = (state, payload) => {
  return {
    ...state,
    uploadStatus: payload.status,
  };
};

const latihanGet = (state, payload) => {
  let items = [
    ...state?.latihans?.filter((item) => item?.id !== payload?.latihan?.id),
    payload.latihan,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    latihans: [...sortItems],
  };
};

const tesGet = (state, payload) => {
  let item = payload.tes && payload.tes.item;
  let isSubmit =
    payload.tes && payload.tes.studentTes && payload.tes.studentTes.time;

  let getPublishDate = item && item.publishDate;
  let getSetting = item && item.setting && JSON.parse(item.setting);
  let getSettingTime = getSetting && getSetting.time;
  let getStartTime = parseISO(getPublishDate);
  let endTime = addMinutes(getStartTime, getSettingTime);

  let statusTes = compareAsc(endTime, new Date()) < 0 ? true : false;

  let questions = item && item.questions && JSON.parse(item.questions);
  let answers = item && item.answers && JSON.parse(item.answers);
  let saveIndex = [];
  let randomQs = [];
  let randomAns = [];

  let setting = item && item.setting && JSON.parse(item.setting);
  let isShareAnswerKey = setting && setting.shareAnswerKey;

  if (!isShareAnswerKey && (statusTes || isSubmit)) {
    questions = [
      {
        no: 1,
        key: 'jquQp7vWkiXPLFPDcqe5w',
        questionType: 'optionQuestion',
        question: 'Pertanyaan di sini...',
        options: [
          'a. pilihan 1',
          'b. pilihan 2',
          'c. pilihan 3',
          'd. pilihan 4',
        ],
        picture: null,
      },
    ];
    answers = [
      { answer: 'jawaban di sini...', key: 'jquQp7vWkiXPLFPDcqe5w', no: 1 },
    ];
  }

  questions &&
    randomArray(questions).forEach((itm, i) => {
      saveIndex.push(itm.no);
      randomQs.push({ ...itm, no: i + 1 });
      randomAns.push({ no: i + 1, key: itm.key, answer: '' });
    });

  let checkScores = item && item.scores && JSON.parse(item.scores);
  let getScore = checkScores?.find(
    (sc) => sc.nis + '' === payload.tes?.nis + ''
  );

  let updateItem = {
    ...item,
    keyAnswers: answers,
    detailScores: !isShareAnswerKey ? undefined : getScore,
    questions: !isShareAnswerKey ? randomQs : questions,
    answers: randomAns,
    saveIndex: saveIndex,
  };

  let updateTes = {
    ...updateItem,
    scores: getScore?.finalScore || 0,
    published: getScore?.published || undefined,
  };
  if (getScore?.published) {
    updateTes.scores = getScore.finalScore || 0;
    updateTes.published = true;
  } else {
    updateTes.scores = undefined;
  }
  let items = [...state?.tess?.filter((i) => i?.id !== item.id), updateTes];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tess: [...sortItems],
  };
};

const tesRemarksGet = (state, payload) => {
  let item = payload.tesRemarks && payload.tesRemarks;
  const filterItem = state.tess.find((i) => i.id === item.id);
  const otherItems = state.tess.filter((i) => i.id !== item.id);
  let updateItems = [...otherItems, { ...filterItem, remarks: item.remarks }];
  let sortItems = sortArrayObject(updateItems, 'createdAt', 'desc');
  return {
    ...state,
    tess: [...sortItems],
  };
};

const navTesStatus = (state, payload) => {
  let tesStatus = state.tesStatus;
  let updateTesStatus = payload.tesStatus && payload.tesStatus.status;
  if (!updateTesStatus)
    return {
      ...state,
      tesStatus: {},
    };

  return {
    ...state,
    tesStatus: { ...tesStatus, ...updateTesStatus },
  };
};

const latihanAnswerGet = (state, payload) => {
  let items;
  if (state?.latihanAnswer && state?.latihanAnswer[0]) {
    items = [
      ...state?.latihanAnswer?.filter(
        (item) => item?.id !== payload?.latihanAnswer?.id
      ),
      payload?.latihanAnswer,
    ];
  } else {
    items = [payload?.latihanAnswer];
  }
  return {
    ...state,
    latihanAnswer: [...items],
  };
};

const studentActivityGet = (state, payload) => {
  let materi = state?.materi;
  let tugas = state?.tugas;
  let latihan = state?.latihan;
  let materiActivity;
  let readMateriActivity = payload?.studentActivity?.materi;
  if (materi?.length > 0) {
    let readMateri = [];
    let unreadMateri = [];
    if (readMateriActivity?.length > 0) {
      for (const m of materi) {
        let statusRead = false;
        for (const rm of readMateriActivity) {
          if (m?.id === rm?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readMateri?.push(m);
        } else {
          unreadMateri?.push(m);
        }
      }
    } else {
      unreadMateri = [...materi];
    }
    materiActivity = {
      read: readMateri,
      unread: unreadMateri,
    };
  }
  let tugasActivity;
  let readTugasActivity = payload?.studentActivity?.tugas;
  if (tugas?.length > 0) {
    let readTugas = [];
    let unreadTugas = [];
    if (readTugasActivity?.length > 0) {
      for (const t of tugas) {
        let statusRead = false;
        for (const rt of readTugasActivity) {
          if (t?.id === rt?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readTugas?.push(t);
        } else {
          unreadTugas?.push(t);
        }
      }
    } else {
      unreadTugas = [...tugas];
    }
    tugasActivity = {
      read: readTugas,
      unread: unreadTugas,
    };
  }
  let latihanActivity;
  let readLatihanActivity = payload?.studentActivity?.latihan;
  if (latihan?.length > 0) {
    let readLatihan = [];
    let unreadLatihan = [];
    if (readLatihanActivity?.length > 0) {
      for (const l of latihan) {
        let statusRead = false;
        for (const rl of readLatihanActivity) {
          if (l?.id === rl?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readLatihan?.push(l);
        } else {
          unreadLatihan?.push(l);
        }
      }
    } else {
      unreadLatihan = [...latihan];
    }
    latihanActivity = {
      read: readLatihan,
      unread: unreadLatihan,
    };
  }
  return {
    ...state,
    studentActivity: {
      materi: materiActivity,
      tugas: tugasActivity,
      latihan: latihanActivity,
    },
  };
};

export default createReducer(initialState, {
  [MATERIS_GET]: materisGet,
  [TUGASS_GET]: tugassGet,
  [STUDENTTUGASS_GET]: studenttugassGet,
  [LATIHANS_GET]: latihansGet,
  [TESS_GET]: tessGet,
  [STUDENTTESS_GET]: studenttessGet,
  [TINGKATS_GET]: tingkatsGet,
  [KELASS_GET]: kelassGet,
  [MATERI_GET]: materiGet,
  [TUGAS_GET]: tugasGet,
  [TUGAS_UPLOADSTATUS]: tugasUploadStatus,
  [LATIHAN_GET]: latihanGet,
  [TES_GET]: tesGet,
  [TES_REMARKS_GET]: tesRemarksGet,
  [NAVTES_STATUS]: navTesStatus,
  [LATIHAN_ANSWER_GET]: latihanAnswerGet,
  [STUDENT_ACTIVITY_GET]: studentActivityGet,
});
