import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, MAINPHOTO_UPDATE, AUTH_ROLEGET } from './authConstant';
import { createReducer } from '../../common/utilities/reducerUtils';
import { relationWithStudent } from '../../common/helpers/optionHelpers';

const initialState = {
  isAuth: false,
  token: null,
  sessionId: null,
  vusn: null,
  userId: null,
  authorities: {},
  name: null,
  username: null,
  mainPhoto: null,
  expiresIn: null,
  role: {},
  dataOptionRelation: null,
};

const authLogin = (state, payload) => {
  return { ...state, ...payload.auth };
};

const authLogout = (state) => {
  return {
    isAuth: false,
    token: null,
    userId: null,
    authorities: {},
    name: null,
    username: null,
    mainPhoto: null,
    expiresIn: null,
  };
};

const authUpdate = (state, payload) => {
  return {
    ...state,
    ...payload.auth,
  };
};

const mainPhotoUpdate = (state, payload) => {
  return {
    ...state,
    ...payload.mainPhoto,
  };
};

const authroleGet = (state, payload) => {
  let role = payload.role;
  let dataOptionRelation;
  if (role && role.detail) {
    let roleDetail = role.detail;
    let filterData = relationWithStudent;
    if (roleDetail.length > 0) {
      for (let i = 0; i < roleDetail.length; i++) {
        let data = filterData.filter((item) => {
          return item.value !== roleDetail[i].ROLE;
        });
        filterData = data;
      }
      dataOptionRelation = filterData;
    } else {
      dataOptionRelation = filterData;
    }
  }
  return {
    ...state,
    role: payload.role,
    dataOptionRelation,
  }
}

export default createReducer(initialState, {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE]: authUpdate,
  [MAINPHOTO_UPDATE]: mainPhotoUpdate,
  [AUTH_ROLEGET]: authroleGet,
});
