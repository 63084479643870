import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

export default function Gallery(props) {
  const [count, setCount] = useState(0);
  const { images } = props;

  useEffect(() => {
    let timer = setTimeout(() => {
      setCount(1);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div style={{ display: 'none' }}>{count}</div>
      <ImageGallery
        items={images}
        showThumbnails={false}
        showBullets={true}
        showNav={false}
        autoPlay={true}
        slideInterval={6000}
        showFullscreenButton={false}
      />
    </>
  );
}
