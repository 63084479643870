import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingButton from '../../App/common/layout/LoadingButton';
import NoteText from '../../App/common/icons/NoteText';
import PencilBoxMultiple from '../../App/common/icons/PencilBoxMultiple';
import PlaylistEdit from '../../App/common/icons/PlaylistEdit';
import AccountFileText from '../../App/common/icons/AccountFileText';
import BookShelf from '../../App/common/icons/Bookshelf'

export default function PembelajaranWidget(props) {
  const {
    auth,
    history,
    loading,
    materis,
    tugass,
    latihans,
    tess,
    tingkats,
    kelass,
    total,
    getPembelajaran,
    handleLink,
  } = props;

  useEffect(() => {
    if (tingkats && tingkats[0] && kelass && kelass[0]) {
      getPembelajaran(auth, history, tingkats, kelass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tingkats, kelass]);

  return (
    <BodyCardHeader handleLink={handleLink}>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 128,
          }}
        >
          <LoadingButton />
        </div>
      )}
      {!loading && <TotalContent handleLink={handleLink} total={total} />}
      <NavPanel
        materis={materis}
        tugass={tugass}
        latihans={latihans}
        tess={tess}
      />
    </BodyCardHeader>
  );
}

function BodyCardHeader({ children, handleLink }) {
  return (
    <div className='column fadein'>
      <div className='card ml-2 mr-2 mb-2'>
        <header
          className='card-header has-background-white has-text-danger hand-pointer'
          onClick={() => handleLink('/pembelajaran')}
        >
          <div className='custom-card-flex is-size-6' style={{ width: '100%' }}>
            <p>
              <span className='flex justify-start align-center'>
                <BookShelf size={18} color={'#f24667'} />
                <span className='ml-1'>Pembelajaran</span>
              </span>
            </p>
          </div>
        </header>
        <div className='card-content'>
          {children}
          </div>
      </div>
    </div>
  );
}

function TotalContent({ total, handleLink }) {
  return (
    <div className='flex justify-around'>
      <TotalItem
        icon={'note-text'}
        color={'link'}
        handleLink={handleLink}
        content={'materi'}
        total={total}
      />
      <TotalItem
        icon={'pencil-box-multiple'}
        color={'primary-dark'}
        handleLink={handleLink}
        content={'tugas'}
        total={total}
      />
      <TotalItem
        icon={'playlist-edit'}
        color={'success'}
        handleLink={handleLink}
        content={'latihan'}
        total={total}
      />
      <TotalItem
        icon={'account-file-text'}
        color={'info'}
        handleLink={handleLink}
        content={'tes'}
        total={total}
      />
    </div>
  );
}

function TotalItem({ handleLink, content, total, icon, color }) {
  let displayIcon = <></>;
  if (icon === 'note-text') {
    displayIcon = <NoteText size={36} color={'#495fc7'} />;
  }
  if (icon === 'pencil-box-multiple') {
    displayIcon = <PencilBoxMultiple size={36} color={'#00947e'} />;
  }
  if (icon === 'playlist-edit') {
    displayIcon = <PlaylistEdit size={36} color={'#00947e'} />;
  }
  if (icon === 'account-file-text') {
    displayIcon = <AccountFileText size={36} color={'#3f8ed0'} />;
  }
  return (
    <div
      onClick={() => handleLink(`/pembelajaran/${content}`)}
      className={`hand-pointer flex flex-column has-text-${color} px-1 py-1`}
    >
      <div className='flex justify-center'>{displayIcon}</div>
      <div className='flex justify-center'>
        {total?.[content]}
        <span className='ml-1 is-capitalized'>{content}</span>
      </div>
    </div>
  );
}

function NavPanel({ materis, tugass, latihans, tess }) {
  const [initial, setInitial] = useState({
    activeTab: 'materi',
  });

  const handleClickTab = (e, val) => {
    e.preventDefault();
    setInitial({
      ...initial,
      activeTab: val,
    });
  };
  return (
    <nav className='panel' style={{boxShadow: 'none'}}>
      <p className='panel-tabs'>
        <PanelTab
          key={'materi'}
          initial={initial}
          handleClickTab={handleClickTab}
          content={'materi'}
        />
        <PanelTab
          key={'tugas'}
          initial={initial}
          handleClickTab={handleClickTab}
          content={'tugas'}
        />
        <PanelTab
          key={'latihan'}
          initial={initial}
          handleClickTab={handleClickTab}
          content={'latihan'}
        />
        <PanelTab
          key={'tes'}
          initial={initial}
          handleClickTab={handleClickTab}
          content={'tes'}
        />
      </p>
      {initial.activeTab === 'materi' && materis && (
        <>
          {!materis.length && <NoContent content={'materi'} />}
          {materis[0] && (
            <PanelBlock key={'bmateri'} items={materis} content={'materi'} />
          )}
        </>
      )}
      {initial.activeTab === 'tugas' && tugass && (
        <>
          {!tugass.length && <NoContent content={'tugas'} />}
          {tugass[0] && (
            <PanelBlock key={'btugas'} items={tugass} content={'tugas'} />
          )}
        </>
      )}
      {initial.activeTab === 'latihan' && latihans && (
        <>
          {!latihans.length && <NoContent content={'latihan'} />}
          {latihans[0] && (
            <PanelBlock key={'blatihan'} items={latihans} content={'latihan'} />
          )}
        </>
      )}
      {initial.activeTab === 'tes' && tess && (
        <>
          {!tess.length && <NoContent content={'tes'} />}
          {tess[0] && <PanelBlock key={'btes'} items={tess} content={'tes'} />}
        </>
      )}
      <br />
    </nav>
  );
}

function PanelTab({ initial, handleClickTab, content }) {
  return (
    <a
      onClick={(e) => handleClickTab(e, content)}
      href='/dashboard'
      className={`${
        initial.activeTab === content
          ? 'is-active has-text-success'
          : 'has-text-grey'
      }`}
    >
      <span className='is-capitalized is-size-6 has-text-weight-semibold'>
        {content}
      </span>
    </a>
  );
}

function PanelBlock(items, content) {
  return (
    <>
      {items.items &&
        items.items.length > 0 &&
        items.items.map((i, key) => {
          if (key === 4) {
            return (
              <Link
                to={`/pembelajaran/${items.content}`}
                className='panel-block is-italic'
                key={i.id}
              >
                more...
              </Link>
            );
          }
          if (key > 4) {
            return null;
          }
          return (
            <Link
              to={`/pembelajaran/${items.content}/${i.id}`}
              className='panel-block pl-4 has-text-grey'
              key={i.id}
            >
              {key + 1}. {i.title}
            </Link>
          );
        })}
    </>
  );
}

function NoContent({ content }) {
  return (
    <Link
      key={content + 'nocontent'}
      to={`/pembelajaran/${content}`}
      className='panel-block is-italic'
    >
      Tidak ada {content}.
    </Link>
  );
}
