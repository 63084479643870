import { RAPORBULANAN_GET, RAPORBULANANDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = { raporbulanan: [] };

const raporbulananGet = (state, payload) => {
  let raporbulanan = payload.raporbulanan;
  if (!raporbulanan) return state;
  return { ...state, raporbulanan: [...raporbulanan] };
};

const raporbulananDetailGet = (state, payload) => {
  if (!payload.raporbulananDetail) return state;
  let raporbulananDetail = {
    ...state.raporbulanan.filter(
      (item) => item.IDXRAPOR === payload.raporbulananDetail.id
    )[0],
    ...payload.raporbulananDetail,
  };
  return {
    ...state,
    raporbulanan: [
      ...state.raporbulanan.filter(
        (item) => item.IDXRAPOR !== payload.raporbulananDetail.id
      ),
      raporbulananDetail,
    ],
  };
};

export default createReducer(initialState, {
  [RAPORBULANAN_GET]: raporbulananGet,
  [RAPORBULANANDETAIL_GET]: raporbulananDetailGet,
});
