import { USERROLE_GET } from './reduxConstant';
import { createReducer } from '../../common/utilities/reducerUtils';

const initialState = {};

const userroleGet = (state, payload) => {
  return { ...payload.val };
};

export default createReducer(initialState, {
  [USERROLE_GET]: userroleGet,
});
