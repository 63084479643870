import { RAPORSEM_GET, RAPORSEMDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = [];

const raporsemGet = (state, payload) => {
  if (!payload.raporsem) return state;
  return [...payload.raporsem];
};

const raporsemDetailGet = (state, payload) => {
  if (!payload.raporsemDetail) return state;
  let raporsemDetail = {
    ...state.filter((item) => item.id === payload.raporsemDetail.id)[0],
    ...payload.raporsemDetail,
  };
  return [
    ...state.filter((item) => item.id !== payload.raporsemDetail.id),
    raporsemDetail,
  ];
};

export default createReducer(initialState, {
  [RAPORSEM_GET]: raporsemGet,
  [RAPORSEMDETAIL_GET]: raporsemDetailGet,
});
