// Fetch with timeout
export const fetchWithTimeOut = async (url, options, time) => {
  let timeout = time * 1000;
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () =>
          reject(
            new Error('Take to long, Connection was reset. Please try again.')
          ),
        timeout
      )
    ),
  ]);
};

export const logsFunc = (act, id, name, logs) => {
  const log = {
    action: act,
    userId: id,
    username: name,
    time: new Date().toISOString(),
  };
  if (!logs) {
    return JSON.stringify([log]);
  } else {
    let prevLogs = JSON.parse(logs);
    return JSON.stringify([...prevLogs, log]);
  }
};
// Get Random Number
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
// trim left space of string
export const ltrim = (str) => {
  if (!str) return str;
  return str.replace(/^\s+/g, '');
};
// File Size Convertion
export const convertSize = (val) => {
  let size = '';
  if (val < 1000000) {
    size = (Math.round(val / 10) / 100).toFixed(2) + ' KB';
  }
  if (val > 1000000 && val < 1000000000) {
    size = (Math.round(val / 10000) / 100).toFixed(2) + ' MB';
  }
  if (val > 1000000000 && val < 1000000000000) {
    size = (Math.round(val / 10000000) / 100).toFixed(2) + ' GB';
  }
  return size;
};

export const ALPHA_NUMBER =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const ALPHA_INDEX = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const useUnamePass = () => {
  let uPass = localStorage.getItem('unamePass');
  return uPass;
};

export const getSession = (id) => {
  let data = sessionStorage.getItem(id);
  return data && JSON.parse(data);
};

export const setSession = (id, data) => {
  sessionStorage.setItem(id, JSON.stringify(data));
  return id;
}

export const removeSession = (id) => {
  sessionStorage.removeItem(id);
  return id;
}