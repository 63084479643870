import React from 'react';
import { Link } from 'react-router-dom';
import AccountMultiplePlus from '../../common/icons/AccountMultiplePlus';

export default function RegisterParents() {
  return (
    <div className='mt-5'>
      <Link to='/register'>
        <span className='flex justify-end align-center has-text-link is-size-7 has-text-right is-italic hand-pointer pr-2'>
          <span className='flex justify-start align-center'>
            <AccountMultiplePlus size={15} color={'#495fc7'} />
            <span className='ml-1'>Register Orang Tua/Wali</span>
          </span>
        </span>
      </Link>
    </div>
  );
}
