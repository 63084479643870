import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openModal } from '../modals/redux/modalActions';
import MessageBadge from './MessageBadge';
import { removeSession } from '../common/helpers/othersHelpers';
import ViewDashboard from '../common/icons/ViewDashboard';
import MessageTextOutline from '../common/icons/MessageTextOutline';
import Account from '../common/icons/Account';
import Apps from '../common/icons/Apps';
import TextBoxEdit from '../common/icons/TextBoxEdit';

const mapState = (state) => ({
  auth: state.auth,
});

const actions = {
  openModal,
};

export class BottomNavbar extends Component {
  _isMounted = false;
  state = {
    changeProfile: false,
    studentSelected: '',
  };

  componentDidMount = () => {
    const { auth } = this.props;
    this.setState({
      studentSelected: auth && auth.userId,
    });
    document.addEventListener('click', (e) => {
      if (!e.target.closest('#changeProfile')) {
        this.setState({
          changeProfile: false,
        });
      }
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    document.removeEventListener('click', (e) => {
      if (!e.target.closest('#changeProfile')) {
        this.setState({
          changeProfile: false,
        });
      }
    });
  };

  handleLink = (value) => {
    const { pathname, history } = this.props;
    if (pathname === value) {
      return;
    }
    history.push(value);
  };

  handleMenu = (val) => {
    const { changeProfile } = this.state;
    const { auth, profile, logout, menus, pathname } = this.props;
    if (val === 'openMenu') {
      let url =
        pathname.split('/').length > 2
          ? '/' + pathname.split('/')[1]
          : pathname;
      this.props.openModal('MenuModal', {
        auth,
        profile,
        logout,
        menus,
        pathname,
        url,
      });
    }
    if (val === 'changeProfile') {
      this.setState({
        changeProfile: !changeProfile,
      });
    }
  };

  handleChangeProfile = (val) => {
    const { auth, history, updateAuth } = this.props;
    const userIdnew = val;
    let selectedItem = auth?.role?.group?.filter((item) => {
      return item.userId === userIdnew;
    })[0];
    this.setState({
      studentSelected: userIdnew,
    });

    if (userIdnew !== auth.userId) {
      updateAuth({ userIdnew, username: selectedItem.name }, auth, history);
    }
  };

  handleCancelTes = () => {
    const { auth, tesStatus, openModal } = this.props;
    openModal('TesCancel', {
      data: {
        auth,
        tesStatus,
      },
    });
  };

  handleClickOut = () => {
    const { auth, history, tesStatus, setTesStatus, setNavTesStatus } =
      this.props;
    let tesId = tesStatus && tesStatus.tesId;
    setTesStatus(auth, history, { tesId, online: false }, tesStatus.id, true);
    removeSession(tesId);
    removeSession('questions-' + tesId);
    removeSession('time-' + tesId);
    removeSession('time-undefined');
    setNavTesStatus(auth, null);
    history.push('/pembelajaran/tes');
  };

  render() {
    const { auth, pathname, unreadPesan, tesStatus, tesMode } = this.props;
    const deviceId = auth && auth.deviceId;
    return (
      <>
        {auth.isAuth && (
          <nav
            id='bottomNavbar'
            className='navbar is-fixed-bottom is-hidden-tablet fade-slide-up'
            role='navigation'
          >
            <div className='navbar-brand'>
              {tesMode && tesStatus && tesStatus.tesStartStatus && (
                <div
                  onClick={() => this.handleCancelTes()}
                  className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                    deviceId?.includes('ios_') ? ' pb-3' : ''
                  }`}
                  style={
                    pathname.includes('/pembelajaran/tes')
                      ? { backgroundColor: '#263544', color: 'white' }
                      : {}
                  }
                >
                  <TextBoxEdit size={20} color={'#ffffff'} />
                  <p className='is-size-7'>Batalkan Tes</p>
                </div>
              )}
              {tesMode &&
                tesStatus &&
                tesStatus &&
                !tesStatus.tesStartStatus && (
                  <div
                    onClick={() => this.handleClickOut()}
                    className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                      deviceId?.includes('ios_') ? ' pb-3' : ''
                    }`}
                    style={
                      pathname.includes('/pembelajaran/tes')
                        ? { backgroundColor: '#263544', color: 'white' }
                        : {}
                    }
                  >
                    <TextBoxEdit size={20} color={'#ffffff'} />
                    <p className='is-size-7'>Keluar Tes Mode</p>
                  </div>
                )}
              {!tesMode && (
                <>
                  <div
                    onClick={() => this.handleLink('/dashboard')}
                    className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                      deviceId?.includes('ios_') ? ' pb-3' : ''
                    }`}
                    style={
                      pathname === '/dashboard'
                        ? { backgroundColor: '#263544', color: 'white' }
                        : {}
                    }
                  >
                    <ViewDashboard
                      size={24}
                      color={pathname === '/dashboard' ? '#ffffff' : '#263544'}
                    />
                    <p className='is-size-7'>Dashboard</p>
                  </div>
                  <div
                    onClick={() => this.handleLink('/pesan')}
                    className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                      deviceId?.includes('ios_') ? ' pb-3' : ''
                    }`}
                    style={
                      pathname === '/pesan'
                        ? { backgroundColor: '#263544', color: 'white' }
                        : {}
                    }
                  >
                    <div style={{ position: 'relative' }}>
                      <MessageTextOutline
                        size={24}
                        color={pathname === '/pesan' ? '#ffffff' : '#263544'}
                      />
                      <MessageBadge unreadPesan={unreadPesan} />
                      <p className='is-size-7'>Pesan</p>
                    </div>
                  </div>
                  {auth?.role?.role === 'Siswa' && (
                    <div
                      onClick={() => this.handleLink('/profil')}
                      className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                        deviceId?.includes('ios_') ? ' pb-3' : ''
                      }`}
                      style={
                        pathname === '/profil'
                          ? { backgroundColor: '#263544', color: 'white' }
                          : {}
                      }
                    >
                      <Account
                        size={24}
                        color={pathname === '/profil' ? '#ffffff' : '#263544'}
                      />
                      <p className='is-size-7'>Profil</p>
                    </div>
                  )}
                  {auth?.role?.role !== 'Siswa' &&
                    auth?.role?.group?.length > 1 && (
                      <div
                        onClick={() => this.handleMenu('changeProfile')}
                        className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                          deviceId?.includes('ios_') ? ' pb-3' : ''
                        }`}
                      >
                        <div id='changeProfile' className='customDropdown'>
                          <div className='customlink'>
                            <Account
                              size={24}
                              color={
                                pathname === '/profil' ? '#ffffff' : '#263544'
                              }
                            />
                            <p className='is-size-7'>Ubah Profil</p>
                          </div>
                        </div>
                        {this.state.changeProfile && (
                          <div className='fadein customdropdownmenu2'>
                            <div className='customdropdowncontent2'>
                              {auth?.role?.group?.map((item, key) => (
                                <div
                                  key={key}
                                  onClick={() =>
                                    this.handleChangeProfile(item.userId)
                                  }
                                  className={`customdropdowncontentlist${
                                    auth && auth.userId === item.userId
                                      ? ' contentlistactive no-pointer'
                                      : ''
                                  }`}
                                >
                                  {item.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  <div
                    onClick={() => this.handleMenu('openMenu')}
                    className={`navbar-item is-expanded is-block has-text-centered hand-pointer2${
                      deviceId?.includes('ios_') ? ' pb-3' : ''
                    }`}
                  >
                    <div className='customdropdown'>
                      <div className='customlink'>
                        <Apps size={24} color={'#263544'} />
                        <p className='is-size-7'>Menu</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </nav>
        )}
      </>
    );
  }
}

export default withRouter(connect(mapState, actions)(BottomNavbar));
