import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../modals/redux/modalActions';
import MenuDetail from './MenuDetail';
import Apps from '../common/icons/Apps';
import Logout from '../common/icons/Logout';
import Close from '../common/icons/Close';

const actions = {
  closeModal,
};

const MenuModal = (props) => {
  const { closeModal, auth, profile, logout, menus, pathname, url } = props;

  const refBox = useRef(null);
  const refBoxContainer = useRef(null);

  const [widthBox, setWidthBox] = useState(0);
  const [heightBox, setHeightBox] = useState(0);
  const [heightContainer, setHeightContainer] = useState(0);
  const [widthItem, setWidthItem] = useState(0);

  useLayoutEffect(() => {
    setWidthBox(refBox.current.offsetWidth);
    setHeightBox(refBox.current.offsetHeight);
    setHeightContainer(refBoxContainer.current.offsetHeight);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidthBox(refBox.current.offsetWidth);
      setHeightBox(refBox.current.offsetHeight);
      setHeightContainer(refBoxContainer.current.offsetHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const menusLength = menus && menus.length + 1;
  const columnItems = widthBox && widthItem && Math.floor(widthBox / widthItem);
  const reminderItems = menusLength && columnItems && menusLength % columnItems;

  let arrayDiv = [];
  if (reminderItems > 0) {
    arrayDiv = new Array(reminderItems).fill(1);
  }

  let heightMenu = heightContainer > 550 ? heightBox - 1 : heightBox - 36;

  console.log(menus);

  return (
    <div
      id='menu-popup'
      className='modal is-active is-hidden-tablet animate pop'
      style={{zIndex: 1000}}
    >
      <div className='modal-background' onClick={closeModal}></div>
      <div
        ref={refBoxContainer}
        className='modal-card content'
        style={{ height: 'calc(100% - 120px)' }}
      >
        <div className='box c-box-menu-modal ml-1 mr-1'>
          <div id='menu-popup' className='c-menu-detail'>
            <div className='is-size-4 c-title-menu-modal has-text-weight-medium has-text-white'>
              <Apps size={26} color={'#ffffff'} />
              <span className='ml-1'>Menu</span>
            </div>
            <hr className='mt-2 mb-2' style={{ height: 1 }} />
            <div ref={refBox}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  height: heightMenu,
                  overflowY: heightContainer > 550 ? 'hidden' : 'auto',
                }}
              >
                {menus &&
                  menus.map((m) => (
                    <MenuDetail
                      key={m.id}
                      widthItem={setWidthItem}
                      profile={profile}
                      pathname={pathname}
                      dataMenu={m}
                      dataAuth={auth?.authorities}
                      url={url}
                      closeModal={closeModal}
                    />
                  ))}
                <div className='tile'>
                  <div
                    onClick={() => logout('logoutNav')}
                    className='has-text-white'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 100,
                      width: 100,
                    }}
                  >
                    <div>
                      <Logout size={32} color={'#ffffff'} />
                    </div>
                    <p className='is-size-7 has-text-centered'>Logout</p>
                  </div>
                </div>
                {reminderItems > 0 &&
                  arrayDiv.map((i, key) => (
                    <div className='tile' key={key}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: 100,
                          width: 100,
                        }}
                        className='has-text-white'
                      ></div>
                    </div>
                  ))}
              </div>
            </div>
            <br />
          </div>
          <div
            onClick={closeModal}
            className='has-text-white c-close-menu-modal'
          >
            <Close size={18} color={'#ffffff'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(MenuModal);
