import React, { useEffect } from 'react';
import { parseISO, differenceInDays } from 'date-fns';
import { format } from 'date-fns/esm';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../App/common/data/siteConfig';
import Bullhorn from '../../App/common/icons/Bullhorn';

export default function AnnouncementTimeLine(props) {
  const {
    auth,
    history,
    openModal,
    announcements,
    getAnnouncements,
    handleLink,
    downloadFile,
  } = props;

  useEffect(() => {
    getAnnouncements({
      auth,
      history,
      tingkat: auth?.active_tingkat,
      kelas: auth?.active_kelas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BodyCardHeader handleLink={handleLink}>
      <AnnouncementContent
        auth={auth}
        history={history}
        announcements={announcements}
        openModal={openModal}
        downloadFile={downloadFile}
      />
    </BodyCardHeader>
  );
}

function BodyCardHeader({ children, handleLink }) {
  return (
    <div className='column ml-2 mr-2 mb-2'>
      <div className='card'>
        <header
          className='card-header has-background-white has-text-primary-dark hand-pointer'
          onClick={() => handleLink('/pengumuman')}
        >
          <div className='custom-card-flex is-size-6' style={{ width: '100%' }}>
            <p>
              <span className='flex justify-start align-center'>
                <Bullhorn size={18} color={'#00947e'} />
                <span className='ml-1'>Pengumuman</span>
              </span>
            </p>
          </div>
        </header>
        <div className='card-content fadein pr-2'>
          <div className='overflow-auto pb-1 px-3' style={{ maxHeight: 300 }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function AnnouncementContent({
  auth,
  history,
  announcements,
  downloadFile,
  openModal,
}) {
  const deviceId = auth && auth.deviceId;
  const handleAttachement = (id, file) => {
    let fileData = file && JSON.parse(file);
    let filelink = fileData && fileData.filelink;
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(filelink);
    if (isImage) {
      openModal('PopUpImageModal', {
        data: SITE_ADDRESS_PEMBELAJARAN + filelink,
      });
    } else {
      if (!deviceId) {
        downloadFile(auth, history, id, fileData);
      }
      if (deviceId) {
        window.ReactNativeWebView.postMessage(
          'url:' + SITE_ADDRESS_PEMBELAJARAN + filelink
        );
      }
    }
  };

  return (
    <>
      {announcements && announcements[0] && (
        <div className='timeline'>
          {announcements && announcements[0] && (
            <header className='timeline-header'>
              <span className='tag is-link'>
                {announcements[0].date &&
                  format(parseISO(announcements[0].date), 'MMM yyyy')}
              </span>
            </header>
          )}
          {announcements &&
            announcements[0] &&
            announcements.map((item, index) => (
              <div className='timeline-item' key={index}>
                <div className='timeline-marker'></div>
                <div className='timeline-content'>
                  <p
                    className={`heading${
                      differenceInDays(
                        item && item.date && parseISO(item.date),
                        new Date()
                      ) >= 0
                        ? ' has-text-info'
                        : ''
                    }`}
                  >
                    {item.date && format(parseISO(item.date), 'd MMM')} -{' '}
                    {item.title}
                  </p>
                  <p
                    className='content is-small'
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {item.description} <br />
                    {item.file && (
                      <span
                        className='mt-1 mr-2 hand-pointer tag is-info is-rounded'
                        onClick={() => handleAttachement(item.id, item.file)}
                      >
                        {item.file && JSON.parse(item.file).filename}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            ))}
          {announcements && announcements?.length > 2 && (
            <div className='timeline-header'>
              <span className='tag is-link'>
                {announcements[announcements.length - 1].date &&
                  format(
                    parseISO(announcements[announcements.length - 1].date),
                    'MMM yyyy'
                  )}
              </span>
            </div>
          )}
        </div>
      )}
      {announcements && announcements?.length < 1 && (
        <p className='is-italic ml-2 mb-1'>Tidak ada Pengumuman.</p>
      )}
    </>
  );
}
