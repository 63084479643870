import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../../App/modals/redux/modalActions';
import MBody from './MBody';
import TimerCheck from '../../../../App/common/icons/TimerCheck';
import ShieldHalfFull from '../../../../App/common/icons/ShieldHalfFull';

const actions = {
  closeModal,
};

function ModalTesSubmit(props) {
  const { data, closeModal } = props;
  const onSubmit = () => {
    let handleSubmit = data.handleSubmit;
    handleSubmit();
    closeModal();
  };
  return (
    <MBody>
      <p>
        <TimerCheck size={64} color={'#3f8ed0'} />
      </p>
      <p className='mt-2'>
        <span className='tag is-info is-medium is-rounded'>
          <ShieldHalfFull size={15} color={'#ffffff'} />
          <span className='ml-1'>Waktu Habis</span>
        </span>
      </p>
      <p className='mt-1'>
        Klik tombol{' '}
        <span className='has-text-info has-text-weight-semibold'>Selesai</span>{' '}
        untuk mensubmit jawaban.
      </p>{' '}
      <p className='is-size-7 is-italic' style={{ width: 300, margin: 'auto' }}>
        Note: Submit jawaban dan tes berakhir.
      </p>
      <div
        className='mt-4'
        style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
      >
        <button
          onClick={onSubmit}
          className='button is-info is-small is-rounded is-outlined'
        >
          Selesai
        </button>
      </div>
    </MBody>
  );
}

export default connect(null, actions)(ModalTesSubmit);
