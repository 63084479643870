import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { sessionLogout as logout } from '../login/redux/authApi';
import Lock from '../common/icons/Lock';
import ArrowLeft from '../common/icons/ArrowLeft';
import Logout from '../common/icons/Logout';

const actions = {
  logout,
};

class NotAuthorizedModal extends Component {
  render() {
    const { history, logout } = this.props;

    return (
      <div className='modal is-active'>
        <div
          className='modal-background'
          style={{ backgroundColor: 'black', opacity: 1 }}
        ></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title has-text-danger flex justify-start align-center'>
             <Lock size={15} color={'#f14668'} /> <p className='ml-1'>Tidak Memiliki otoritas</p>
            </p>
          </header>
          <section className='modal-card-body'>
            <div>
              <br />
              <p className='has-text-centered'>Anda tidak memiliki otoritas!</p>
              <p className='has-text-centered' style={{ marginTop: 15 }}>
                {history.location.pathname !== '/dashboard' && (
                  <button
                    className='button is-small is-rounded is-info is-outlined'
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <ArrowLeft size={15} color={'#3f8ed0'} /> <p className='ml-1'>Kembali</p>
                  </button>
                )}
                <button
                  className='button is-small is-rounded is-danger is-outlined ml-2'
                  onClick={() => logout('logoutModal', history)}
                >
                  <Logout size={15} color={'#f14668'} />
                  Logout
                </button>
              </p>
              <br />
            </div>
          </section>
          <footer className='modal-card-foot'></footer>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(NotAuthorizedModal));
