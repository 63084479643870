import React from 'react';
import { SCHOOL_NAME } from '../../common/data/siteConfig';
import LogoPicture from '../../../images/logo.webp';

export default function SchoolTitle({ children, title }) {
  return (
    <div className='media'>
      <div className='media-content'>
        <div className='content'>
          <div className='has-text-centered mb-5'>
            <img
              alt='profile-pic'
              src={LogoPicture}
              style={{
                width: 145,
                height: 'auto',
              }}
            />
            <p>
              <span className='has-text-weight-bold is-size-4'>
                {SCHOOL_NAME}
              </span>
              <br />
              <span className='is-size-6'>{title}</span>
            </p>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
