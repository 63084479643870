import { SWIPE_TOGGLE } from './reduxConstant';
import { createReducer } from '../../utilities/reducerUtils';

const initialState = false;

const swipeToggle = (state, payload) => {
  return payload.status;
};

export default createReducer(initialState, {
  [SWIPE_TOGGLE]: swipeToggle,
});
