import { getUnixTime } from 'date-fns';

// Get Random Number
export const timeToUnix = (val) => {
  let amPM = val.split(' ')[2];
  let dataDate = val.split(' ')[0];
  let Day = dataDate.split('/')[1];
  let Month = dataDate.split('/')[0] - 1;
  let Year = dataDate.split('/')[2];
  let dataTime = val.split(' ')[1];
  let Hour =
    amPM === 'PM'
      ? parseInt(dataTime.split(':')[0]) + 12
      : dataTime.split(':')[0];
  let Minute = dataTime.split(':')[1];
  let Second = dataTime.split(':')[2];
  let timeToUnix = getUnixTime(
    new Date(
      parseInt(Year),
      parseInt(Month),
      parseInt(Day),
      parseInt(Hour),
      parseInt(Minute),
      parseInt(Second)
    )
  );
  return timeToUnix;
};

export const dateToLocalTime = (date) => {
  let formatDate = date.toLocaleString('sv', { timeZone: 'Asia/Jakarta' }).replace(' ', 'T');
  return formatDate;
}
