import { DASHBOARD_GET, UNREAD_PESAN_GET, UNREAD_PESAN_UPDATE } from './reduxConstant';

export const dashboardGet = (dashboard) => {
  return {
    type: DASHBOARD_GET,
    payload: {
      dashboard,
    },
  };
};

export const unreadPesanGet = (unreadPesan) => {
  return {
    type: UNREAD_PESAN_GET,
    payload: {
      unreadPesan,
    },
  };
};

export const unreadPesanUpdate = (unreadPesan) => {
  return {
    type: UNREAD_PESAN_UPDATE,
    payload: {
      unreadPesan,
    },
  };
};
