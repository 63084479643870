import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Icons from '../common/icons/Icons';

export default function MenuDetail(props) {
  const { dataMenu, profile, url, closeModal, widthItem } = props;
  const history = useHistory();
  const refItem = useRef(null);

  useLayoutEffect(() => {
    widthItem(refItem?.current?.offsetWidth);
  }, [widthItem]);

  useEffect(() => {
    function handleResize() {
      widthItem(refItem?.current?.offsetWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  function handleLink(value) {
    if (url === value) {
      return;
    }
    closeModal();
    history.push(value);
  }

  if (dataMenu?.id === 'raporketuntasan' && !profile?.rapor_ketuntasan) {
    return <></>;
  }
  if (dataMenu?.id === 'raporprojek' && !profile?.rapor_projek) {
    return <></>;
  }

  return (
    <div className='tile'>
      <div
        ref={refItem}
        onClick={() => handleLink(dataMenu.url)}
        className={`flex flex-column justify-center align-center has-text-white${
          url === dataMenu.url ? ' no-pointer c-active-menu' : ''
        }`}
        style={{
          height: 100,
          width: 100,
        }}
      >
        <div>
          <Icons size={32} color={'#ffffff'} icon={dataMenu.icon} />
        </div>
        <p className='is-size-7 has-text-centered c-height-25'>
          {dataMenu.title}
        </p>
      </div>
    </div>
  );
}
