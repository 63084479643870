import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../modals/redux/modalActions';

const actions = {
  closeModal,
};

class PopUpImageModal extends Component {
  render() {
    const { data, closeModal } = this.props;
    const url = data;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-content'>
          <div className='image has-text-centered'>
            <img
              src={url}
              alt='preview'
              style={{
                display: 'block',
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
              }}
            />
          </div>
        </div>
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={closeModal}
        ></button>
      </div>
    );
  }
}

export default connect(null, actions)(PopUpImageModal);
