import React, { Component } from 'react';
import { SITE_ADDRESS } from '../../common/data/siteConfig';
import Menu from './menu/Menu';
import UserProfile from '../../../images/user-default.png';
import SideMenuTesMode from './SideMenuTesMode';
import Logout from '../../common/icons/Logout';

class SideMenu extends Component {
  componentDidMount() {
    this.handleTogglerLoad();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.swipe !== this.props.swipe) {
      let sideMenu = document.getElementsByClassName('menu-container')[0];
      sideMenu.classList.add('active');
      this.props.handleTogglerOnOff(this.props.isTogglerActive);
      this.props.swipeToggleStatus(this.props.swipe);
      const burger = document.getElementById('burger');
      if (burger) {
        burger.classList.add('active');
        burger.classList.remove('not-active');
      }
    }
  }

  handleTogglerLoad = () => {
    let container = document.body.parentElement;
    let navBurger = document.getElementsByClassName('navbar-burger')[0];
    let sideMenu = document.getElementsByClassName('menu-container')[0];
    let logoutBtn = document.getElementById('logoutBtn');
    let menuList = document.getElementsByClassName('menu-list')[1];
    container.addEventListener('click', (event) => {
      if (navBurger.contains(event.target)) {
        this.props.handleInsideClick(true);
      } else {
        this.props.handleInsideClick(false);
      }
      if (
        this.props.toggler &&
        sideMenu.classList.contains('active') &&
        menuList.contains(event.target)
      ) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (!this.props.inside && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (this.props.swipe && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (event.target.classList.contains('has-submenu')) {
        this.props.handleTogglerOnOff(true);
        this.props.handleInsideClick(true);
        sideMenu.classList.remove('not-active');
        sideMenu.classList.add('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.remove('not-active');
          burger.classList.add('active');
        }
      }
      if (logoutBtn?.contains(event.target)) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
      }
    });
  };

  render() {
    const {
      auth,
      menus,
      logout,
      history,
      toggler,
      pathname,
      profile,
      openModal,
      tesMode,
      tesStatus,
      setTesStatus,
      setNavTesStatus,
    } = this.props;

    return (
      <>
        <div
          id='sm'
          className={
            toggler === true
              ? 'menu-container active has-background-white'
              : 'menu-container has-background-white'
          }
        >
          <div className='menu-wrapper py-1'>
            <aside className='menu'>
              <div className='menu-list profile-sidebar mt-2'>
                <div className='level' style={{ marginBottom: 8 }}>
                  <div className='level-item'>
                    <figure className='image is-48x48'>
                      <div
                        style={{
                          width: 48,
                          height: 48,
                          backgroundImage: `url(${
                            profile && profile.mainPhoto
                              ? SITE_ADDRESS + profile.mainPhoto
                              : UserProfile
                          })`,
                          borderRadius: '50%',
                          backgroundSize: 'cover',
                        }}
                      />
                    </figure>
                  </div>
                </div>
                <div style={{ marginBottom: 4, wordWrap: 'break-word' }}>
                  <div className='flex-column-center'>
                    <div className='subtitle is-7 mt-1 has-text-weight-semibold has-text-grey-lighter is-capitalized has-text-centered'>
                      <p>{auth?.name?.toLowerCase() ?? auth?.username?.toLowerCase()}</p>
                      <p className='mt-1'>{auth.nis}</p>
                      {auth?.role?.role !== 'Siswa' && (
                        <p className='is-lowercase mt-1'>({auth?.vusn})</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ul
                className='menu-list'
                style={{ borderTop: '1px solid #1F2530' }}
              >
                {tesMode && (
                  <SideMenuTesMode
                    auth={auth}
                    tesMode={tesMode}
                    tesStatus={tesStatus}
                    setTesStatus={setTesStatus}
                    setNavTesStatus={setNavTesStatus}
                    history={history}
                    openModal={openModal}
                  />
                )}

                {!tesMode && (
                  <>
                    <li>
                      <div
                        id='Navigation'
                        className='disable-select is-size-6 has-text-grey-lighter'
                      >
                        Menu
                      </div>
                    </li>
                    {menus.map((m) => (
                      <Menu
                        pathname={pathname}
                        key={m.id}
                        dataMenu={m}
                        dataAuth={auth.authorities}
                        profile={profile}
                      />
                    ))}
                    <li>
                      <div
                        id='logoutBtn'
                        onClick={() => logout('logoutNav')}
                        className='disable-select is-size-6 custom-text-overflow custom-hover-light-info has-text-grey-lighter flex justify-start align-center'
                      >
                        <div className='flex align-center pl-2 my-1'>
                          <Logout size={15} color={'#ffffff'} />
                          <div className='ml-2'>Logout</div>
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </ul>
              <br />
              <br />
            </aside>
          </div>
        </div>
      </>
    );
  }
}

export default SideMenu;
