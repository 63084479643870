import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import { announcementsGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
import { saveAs } from 'file-saver';

export const getAnnouncements = ({ auth, history, tingkat, kelas }) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getAnnouncements' });
    const formData = new FormData();
    formData.append('vusn', auth?.vusn);
    formData.append('userId', auth?.userId);
    formData.append('sessionId', auth?.sessionId);
    formData.append('deviceId', auth?.deviceId);
    formData.append('tingkat', tingkat);
    formData.append('kelas', kelas);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'announcement/student',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const announcements = response.announcements;
      dispatch(announcementsGet(announcements));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, announcementId, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'portalsetting/download-file/' +
          announcementId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
