import React from 'react';

export default function SectionBody({ children }) {
  return (
    <section
      className='hero is-fullheight gradient-login flex justify-center align-center'
      style={{
        marginTop: -52,
        width: '100%',
        overflow: 'hidden',
        height: 'calc(100% - 52px)',
      }}
    >
      {children}
    </section>
  );
}
