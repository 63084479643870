import React from 'react'
import LoginIcon from '../../common/icons/LoginIcon'

export default function BackToLogin({ inBackToLogin }) {
  return (
    <div className='mt-3 flex justify-end align-center'>
      <div
        className='is-link is-size-7 is-small is-rounded is-outlined flex justify-end align-center hand-pointer px-2 py-1'
        onClick={() => inBackToLogin()}
        style={{ width: 'max-content', borderRadius: 4 }}
      >
        <LoginIcon size={15} color={'#495fc7'} />
        <span className='ml-1'>Kembali ke halaman Login</span>
      </div>
      <br />
      <br />
    </div>
  )
}
