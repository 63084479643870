import React from 'react';

export default function Copyright() {
  return (
    <p
      className='has-text-centered is-size-7 has-text-dark'
      style={{ marginTop: -14, marginBottom: 8 }}
    >
      Copyright © {new Date().getFullYear()} Skoolas by{' '}
      <span className='has-text-weight-semibold'>Shaktavisi</span>
    </p>
  );
}
