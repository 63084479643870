import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../App/modals/redux/modalActions';
import { deleteAccount } from './redux/reduxApi';

const actions = {
  deleteAccount,
  closeModal,
};

class DeleteAccountModal extends Component {
  onDelete = (auth, history, uPass) => {
    const { deleteAccount, closeModal } = this.props;
    deleteAccount(auth, history);
    if (uPass && uPass.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    closeModal();
  };

  render() {
    const { auth, username, history, uPass, closeModal } = this.props;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <div className='box has-background-white ml-1 mr-1'>
            <div onClick={closeModal} className='has-text-right' style={{position: 'relative', right: -10, top: -14}}>
              <i className='fas fa-times' />
            </div>
            <p>
              Apakah Anda ingin menghapus akun{' '}
              <span className='has-text-danger has-text-weight-semibold'>{`${username}`}</span>{' '}
              ?
            </p>
            <div className='has-text-centered mt-4 mb-2'>
              <button
                onClick={() => this.onDelete(auth, history, uPass)}
                className='button is-small is-danger'
              >
                Proses
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(DeleteAccountModal);
