// !! Need to Update Icon SVG !!
// ./src/App/common/icons/Icons.js
export const Menus = [
  {
    id: 'dashboard',
    icon: 'view-dashboard',
    title: 'Dashboard',
    alias: 'dashboard',
    auth: { dashboard_view: false },
    url: '/dashboard',
    order: '001',
    subm: [],
  },
  {
    id: 'profil',
    icon: 'account-box',
    title: 'Profil',
    alias: 'profil',
    auth: { profil_view: false },
    url: '/profil',
    order: '002',
    subm: [],
  },
  {
    id: 'presensi',
    icon: 'format-list-checks',
    title: 'Presensi',
    alias: 'presensi',
    auth: { presensi_view: false },
    url: '/presensi',
    order: '003',
    subm: [],
  },
  {
    id: 'jadwal',
    icon: 'clock-time-seven-outline',
    title: 'Jadwal',
    alias: 'jadwal',
    auth: { jadwal_view: false },
    url: '/jadwal',
    order: '004',
    subm: [],
  },
  {
    id: 'pembelajaran',
    icon: 'bookshelf',
    title: 'Pembelajaran',
    alias: 'pembelajaran',
    auth: { pembelajaran_view: false },
    url: '/pembelajaran',
    order: '005',
    subm: [],
  },
  {
    id: 'raporbulanan',
    icon: 'file-document',
    title: 'Rapor Bulanan',
    alias: 'rapor-bulanan',
    auth: { raporbulanan_view: false },
    url: '/rapor-bulanan',
    order: '006',
    subm: [],
  },
  {
    id: 'raporsem',
    icon: 'file-chart',
    title: 'Rapor Semester',
    alias: 'rapor-sem',
    auth: { raporsem_view: false },
    subm: [],
    url: '/rapor-sem',
    order: '007',
  },
  {
    id: 'raporprojek',
    icon: 'file-compare',
    title: 'Rapor Projek',
    alias: 'rapor-projek',
    auth: { raporprojek_view: false },
    subm: [],
    url: '/rapor-projek',
    order: '009',
  },
  {
    id: 'announcement',
    icon: 'bullhorn',
    title: 'Pengumuman',
    alias: 'pengumuman',
    auth: { announcement_view: false },
    url: '/pengumuman',
    order: '010',
    subm: [],
  },
  {
    id: 'kalender',
    icon: 'calendar-month',
    title: 'Kalender',
    alias: 'kalender',
    auth: { kalender_view: false },
    url: '/kalender',
    order: '011',
    subm: [],
  },
  {
    id: 'pesan',
    icon: 'message-text-outline',
    title: 'Pesan',
    alias: 'pesan',
    auth: { pesan_view: false },
    url: '/pesan',
    order: '012',
    subm: [],
  },
  {
    id: 'pengaturan',
    icon: 'account-cog',
    title: 'Pengaturan',
    alias: 'pengaturan',
    auth: { pengaturan_view: false },
    url: '/pengaturan',
    order: '013',
    subm: [],
  },
];
