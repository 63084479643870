import React from 'react';
import SectionBody from './SectionBody';
import Herobody from './Herobody';
import ContainerBody from './ContainerBody';
import LoadingButton from '../../common/layout/LoadingButton';

export default function LoadingBody({ color }) {
  return (
    <SectionBody>
      <Herobody
        cstyle={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: color || '#e1e6f6',
          height: '100%',
          width: '100%',
        }}
      >
        <ContainerBody>
          <div
            className='flex justify-center align-center'
            style={{ width: '100%', height: 300 }}
          >
            <LoadingButton />
          </div>
        </ContainerBody>
      </Herobody>
    </SectionBody>
  );
}
