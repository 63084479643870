import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import { pesansGet, statusUpdate, pesanDelete } from './reduxAction';
import { unreadPesanUpdate } from '../../../dashboard/redux/reduxAction';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
import { saveAs } from 'file-saver';

export const getPesans = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPesans' });
    if (auth && !auth.isAuth) {
      dispatch(asyncActionFinish());
      return;
    }
    const vusn = auth.vusn;
    const userId = auth.userId;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pesan-pribadi/' + userId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const pesans = response.pesanpribadis;
      dispatch(pesansGet(pesans));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const updateStatus = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateStatus' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const messageId = values.messageId;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('messageId', messageId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pesan-pribadi/status/' + messageId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const pesan = response.pesanpribadi;
      dispatch(unreadPesanUpdate({messageId}));
      dispatch(statusUpdate(pesan));
      dispatch(asyncActionFinish());
      return true;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const deletePesan = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deletePesan' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const studentMessageId = values.studentMessageId;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('studentMessageId', studentMessageId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pesan-pribadi/delete-student-message/' + studentMessageId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const pesan = response.pesanpribadi;
      dispatch(pesanDelete(pesan));
      dispatch(asyncActionFinish());
      return true;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, messageId, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pesan-pribadi/download-file/' + messageId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
