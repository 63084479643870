import React from 'react';
import ReactDOM from 'react-dom';
import './App/main/styles.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from './App/main/App'
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from './App/store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import ScrollToTop from './App/common/utilities/ScrollToTop';

const { store, persistor } = configureStore();

const rootEl = document.getElementById('root');

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <ReduxToastr
            position='bottom-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
          />
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./App/main/App', () => {
    setTimeout(render);
  });
}

render();
