import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../../App/modals/redux/modalActions';
import ModalTesLeavePageButtonBack from './MTesLeavePageButtonBack';
import { trackLeavePage } from '../redux/reduxApi';
import { useHistory } from 'react-router-dom';
import { differenceInSeconds, parseISO } from 'date-fns';
import {
  setSession,
  getSession,
  removeSession,
} from '../../../../App/common/helpers/othersHelpers';
import ShieldHalfFull from '../../../../App/common/icons/ShieldHalfFull';
import ExitRun from '../../../../App/common/icons/ExitRun';
import MBody from './MBody';

const actions = {
  closeModal,
  trackLeavePage,
};

function ModalTesLeavePage(props) {
  let history = useHistory();
  const { data, closeModal, trackLeavePage } = props;
  const auth = data && data.auth;
  // const tesStatus = data.tesStatus;
  const startTimeUnfocus = data.startTimeUnfocus;
  const value = data.value;
  const [run, setRun] = useState(false);

  const logChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      setRun(true);
      return;
    }
  };

  const checkFocus = () => {
    setRun(true);
  };

  const checkBlur = () => {
    setRun(false);
  };

  useEffect(() => {
    let checkStartLeavePageSession = getSession('startTimeUnfocus');
    if (!checkStartLeavePageSession)
      setSession('startTimeUnfocus', startTimeUnfocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', logChange);
    window.addEventListener('focus', checkFocus, false);
    window.addEventListener('blur', checkBlur, false);
    return () => {
      document.removeEventListener('visibilitychange', logChange);
      window.removeEventListener('focus', checkFocus, false);
      window.removeEventListener('blur', checkBlur, false);
    };
  }, []);

  const onClose = () => {
    let startTimeUnfocusSession = getSession('startTimeUnfocus');
    let startLeaveTime = startTimeUnfocusSession
      ? startTimeUnfocusSession
      : startTimeUnfocus;
    let endLeaveTime = new Date();
    let timeLeavePage = {
      inSeconds: differenceInSeconds(endLeaveTime, parseISO(startLeaveTime)),
      startLeave: startLeaveTime,
      endLeave: endLeaveTime.toISOString(),
    };
    let student = {
      id: value.id,
      tesId: value.tesId,
      nis: value.nis,
      username: value.vusn,
      activity: value.activity,
    };
    trackLeavePage(auth, history, timeLeavePage, student);
    removeSession('startTimeUnfocus');
    closeModal();
  };

  return (
    <MBody>
      <p className='mt-5'>
        <ExitRun size={64} color={'#f24667'} />
      </p>
      <p className='mt-2'>
        <span className='tag is-danger is-medium is-rounded'>
          <ShieldHalfFull size={15} color={'#ffffff'} />
          <span className='ml-1'>Ujian Sedang Berlangsung</span>
        </span>
      </p>
      <p className='mt-1'>Kamu terdeteksi meninggalkan halaman ujian.</p>{' '}
      <p className='is-size-7 is-italic' style={{ width: 320, margin: 'auto' }}>
        Note: Jawaban yang belum disubmit akan hilang jika kamu menutup halaman
        ujian!
      </p>
      <div
        className='mt-4'
        style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
      >
        <ModalTesLeavePageButtonBack onClose={onClose} run={run} />
      </div>
    </MBody>
  );
}

export default connect(null, actions)(ModalTesLeavePage);
