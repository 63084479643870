import { swipeToggle } from "./reduxAction";
import {
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
import { ASYNC_ACTION_START } from "../async/asyncConstant";

export const swipeToggleStatus = (status) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'swipeToggleStatus' });
    try {
      dispatch(swipeToggle(status));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };
};
