import React from 'react'

export default function MBody({children}) {
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={() => {}}></div>
      <div style={{ width: 500, minWidth: 'max-content' }}>
        <div className='' style={{ overflow: 'hidden' }}>
          <div className='card'>
            <div className='card-content'>
              <div id='fadein2' className='has-text-centered mt-3 mb-3'>
               {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
