import React from 'react';
import { removeSession } from '../../common/helpers/othersHelpers';
import Logout from '../../common/icons/Logout';
import FileDocumentRemove from '../../common/icons/FileDocumentRemove';
import ArrowLeft from '../../common/icons/ArrowLeft';
import ArrowRight from '../../common/icons/ArrowRight';
import { dateToLocalTime } from '../../common/helpers/timeConvert';

const tesModeStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'calc(100vh - 200px)',
};

export default function SideMenuTesMode({
  auth,
  history,
  tesMode,
  tesStatus,
  setTesStatus,
  setNavTesStatus,
  openModal,
}) {
  let checkPathname = window.location.pathname;

  if (!checkPathname.includes('/pembelajaran/tes')) {
    removeSessionTes();
  }

  // let endTime = tesStatus && tesStatus.endTime;
  // let isTesEnded = endTime && new Date() >= new Date(endTime) ? true : false;

  function handleCancelTes() {
    openModal('TesCancel', {
      data: {
        auth,
        tesStatus,
      },
    });
  }

  function removeSessionTes() {
    let tesId = tesStatus && tesStatus.tesId;
    let value = {
      id: tesStatus.id,
      tesId: tesStatus.tesId,
      online: false,
      lastSeen: dateToLocalTime(new Date()),
    };
    setTesStatus(auth, history, value, true);
    removeSession(tesId);
    removeSession('questions-' + tesId);
    removeSession('time-' + tesId);
    removeSession('time-undefined');
    setNavTesStatus(auth, null);
  }

  function handleClickOut() {
    removeSessionTes();
    history.push('/pembelajaran/tes');
  }

  return (
    <div style={tesModeStyle}>
      <div>
        {/* {isTesEnded && <StatusTesEnd />} */}
        {tesMode && tesStatus && tesStatus.loadingStatus === undefined && (
          <LoaderBar />
        )}
        {tesMode && tesStatus && tesStatus.loadingStatus !== undefined && (
          <ModeTesDetail />
        )}
        {tesMode && tesStatus && tesStatus.tesStartStatus && (
          <ButtonCancel handleCancelTes={() => handleCancelTes()} />
        )}
        {tesMode &&
          tesStatus &&
          tesStatus.loadingStatus !== undefined &&
          !tesStatus.tesStartStatus && (
            <ButtonOut handleClickOut={() => handleClickOut()} />
          )}
      </div>
    </div>
  );
}

// function StatusTesEnd() {
//   return (
//     <li>
//       <div
//         id='Navigation'
//         className='fadein disable-select is-size-6 has-text-centered has-text-grey-lighter mt-5'
//       >
//         <div className='tag is-rounded is-small' style={{ cursor: 'auto' }}>
//           <TextBoxCheck size={15} color={'#000000'} /> TES BERAKHIR
//         </div>
//       </div>
//     </li>
//   );
// }

function LoaderBar() {
  return (
    <div
      className='flex justify-center align-center'
      style={{
        width: '100%',
        height: 220,
        paddingTop: 200,
      }}
    >
      <div className='loader-bar'></div>
    </div>
  );
}

function ModeTesDetail() {
  return (
    <>
      <li>
        <div
          id='Navigation'
          className='fadein disable-select is-size-6 has-text-grey-lighter'
        >
          Mode Tes
        </div>
      </li>
      <li>
        <div
          id='Navigation'
          className='fadein disable-select is-size-6 has-text-grey-lighter'
        >
          <p className='mb-4'>Petunjuk Pengerjaan Tes :</p>
          <p className='is-size-7 mb-3'>
            Klik tombol{' '}
            <span className='has-text-weight-bold has-text-warning'>Start</span>{' '}
            untuk memulai pengerjaan soal.
          </p>
          <p className='is-size-7 mb-3'>
            Klik tombol{' '}
            <span className='has-text-weight-bold has-text-warning'>
              <ArrowLeft size={15} color={'#ffe08a'} />
            </span>
            <span>
              <ArrowRight size={15} color={'#ffe08a'} />
            </span>{' '}
            atau klik{' '}
            <span className='has-text-weight-bold has-text-warning'>
              Navigasi Nomer
            </span>{' '}
            untuk berganti soal.
          </p>
          <p className='is-size-7 mb-3'>
            Klik{' '}
            <span className='has-text-warning has-text-weight-bold'>
              ? Ragu
            </span>{' '}
            untuk menandai soal agar bisa dicek ulang dan klik kembali untuk
            menghilangkan.
          </p>
          <p className='is-size-7 mb-3'>
            Jika pengerjaan soal telah selesai, klik{' '}
            <span className='has-text-warning has-text-weight-bold'>
              Selesai
            </span>{' '}
            untuk mensubmit jawaban.
          </p>
          <p className='is-size-7 mb-3'>
            Klik tombol{' '}
            <span className='has-text-warning has-text-weight-bold'>Batal</span>{' '}
            untuk membatalkan tes dan mereset jawaban.
          </p>
          <p className='is-size-7 mb-3'>
            Ketika ujian belum dimulai, klik tombol{' '}
            <span className='has-text-warning has-text-weight-bold'>
              Keluar
            </span>{' '}
            untuk keluar halaman tes.
          </p>
        </div>
      </li>
    </>
  );
}

function ButtonCancel({ handleCancelTes }) {
  return (
    <li>
      <div
        id='Navigation'
        className='disable-select is-size-6 has-text-grey-lighter has-text-centered mt-4 mb-4'
      >
        <button
          className='button is-rounded is-small'
          onClick={() => handleCancelTes()}
        >
          <FileDocumentRemove size={15} color={'#000000'} />{' '}
          <p className='ml-1'>Batal</p>
        </button>
      </div>
    </li>
  );
}

function ButtonOut({ handleClickOut }) {
  return (
    <div
      id='Navigation'
      className='fadein disable-select is-size-6 has-text-grey-lighter has-text-centered mt-5'
    >
      <button
        onClick={() => handleClickOut()}
        className='button is-rounded is-small'
      >
        <Logout size={15} color={'#000000'} /> <p className='ml-1'>Keluar</p>
      </button>
    </div>
  );
}
