import { RAPORSEMESTER_GET, RAPORSEMESTERDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = { raporsemester: [] };

const raporsemesterGet = (state, payload) => {
  let raporsemester = payload.raporsemester;
  if (!raporsemester) return state;
  return { ...state, raporsemester: [...raporsemester] };
};

const raporsemesterDetailGet = (state, payload) => {
  if (!payload.raporsemesterDetail) return state;
  let raporsemesterDetail = {
    ...state.raporsemester.filter(
      (item) => item.IDXRAPOR === payload.raporsemesterDetail.id
    )[0],
    ...payload.raporsemesterDetail,
  };
  return {
    ...state,
    raporsemester: [
      ...state.raporsemester.filter(
        (item) => item.IDXRAPOR !== payload.raporsemesterDetail.id
      ),
      raporsemesterDetail,
    ],
  };
};

export default createReducer(initialState, {
  [RAPORSEMESTER_GET]: raporsemesterGet,
  [RAPORSEMESTERDETAIL_GET]: raporsemesterDetailGet,
});
