import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import {
  materisGet,
  tugassGet,
  studenttugassGet,
  tugasUploadStatus,
  latihansGet,
  tessGet,
  studenttessGet,
  tingkatsGet,
  kelassGet,
  materiGet,
  tugasGet,
  latihanGet,
  tesGet,
  tesRemarksGet,
  navTesStatus,
  latihanAnswerGet,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
import { saveAs } from 'file-saver';
import { toastr } from 'react-redux-toastr';

export const getMateris = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateris' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.materis;
      const total = response.totalMateri;
      dispatch(materisGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTugass = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugass' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.tugass;
      const total = response.totalTugas;
      dispatch(tugassGet({ items, total, nis: auth.nis }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentTugass = (auth, history, tingkat, kelas) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentTugass' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/studenttugas/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.studenttugass;
      const total = response.totalStudenttugas;
      dispatch(studenttugassGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihans = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihans' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.latihans;
      const total = response.totalLatihan;
      dispatch(latihansGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTess = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTess' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.tess;
      const total = response.totalTes;
      dispatch(tessGet({ items, total, nis: auth.nis }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentTess = (auth, history, tingkat, kelas) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentTess' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/studenttes/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.studenttess;
      const total = response.totalStudenttes;
      dispatch(studenttessGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTingkats = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTingkats' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('tingkat', auth.active_tingkat);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tingkat/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tingkats = response.tingkats;
      const kelass = response.kelass;
      dispatch(tingkatsGet(tingkats));
      dispatch(kelassGet(kelass));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getMateri = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateri' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const materi = response.materi;
      dispatch(materiGet(materi));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugas' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        toastr.error('Error', `Data belum bisa diakses!`);
        history.push('/pembelajaran/tugas');
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      dispatch(tugasGet({ item: tugas, nis: auth.nis }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugasAnswerKey = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugasAnswerKey' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/answer-key/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        toastr.error('Error', `Data belum bisa diakses!`);
        history.push('/pembelajaran/tugas');
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      dispatch(tugasGet({ item: tugas, nis: auth.nis }));
      dispatch(asyncActionFinish());
      if (response?.tugas) {
        return 'finish';
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadAttachment = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadAttachment' });
    dispatch(tugasUploadStatus({ status: 'start' }));
    try {
      let tugasId = values.id;
      const remarks = {
        title: values.title,
        description: values.description,
        questions: values.questions,
        remarks: values.remarks,
      };
      const formData = new FormData();
      formData.append('token', auth.token);
      formData.append('userId', auth.userId);
      formData.append('username', auth.username);
      formData.append('name', auth.name);
      formData.append('id', values.submittedId);
      formData.append('no', auth.noabsen);
      formData.append('nis', auth.nis);
      formData.append('siswa', auth.username);
      formData.append('year', auth.active_year);
      formData.append('sem', auth.active_sem);
      formData.append('kelas', auth.active_kelas);
      formData.append('tingkat', auth.active_tingkat);
      formData.append('jenjang', auth.active_jenjang);
      formData.append('tugasId', values.id);
      formData.append('pelajaran', values.pelajaran);
      formData.append('tags', values.tags);
      formData.append('remarks', JSON.stringify(remarks));
      formData.append('file', values.file);
      formData.append('filename', values.filename);
      formData.append('filesize', values.filesize);
      formData.append('filetype', values.filetype);
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/tugas/upload-attachment/' +
          tugasId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 201, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      history.push('/pembelajaran/tugas/' + tugasId);
      dispatch(tugasGet(tugas));
      dispatch(tugasUploadStatus({ status: 'finish' }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFile = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFile' });
    let submittedId = values.submitted.id;
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('filelink', file.filelink);
    formData.append('id', submittedId);
    formData.append('tugasId', values.id);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/tugas/delete-file/' +
          submittedId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      history.push('/pembelajaran/tugas/' + tugas.id);
      dispatch(tugasGet(tugas));
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitTugas' });
    const remarks = {
      title: values.title,
      description: values.description,
      questions: values.questions,
      remarks: values.remarks,
    };
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('id', values.submittedId);
    formData.append('no', auth.noabsen);
    formData.append('nis', auth.nis);
    formData.append('siswa', auth.name ?? auth.username);
    formData.append('name', auth.name);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('kelas', auth.active_kelas);
    formData.append('tingkat', auth.active_tingkat);
    formData.append('jenjang', auth.active_jenjang);
    formData.append('tugasId', values.id);
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('time', values.time);
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', values.tags);
    formData.append('remarks', JSON.stringify(remarks));
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/submit/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = response.tugas;
      history.push({
        pathname: '/pembelajaran/tugas/submit/' + tugas.tugasId,
        state: { ...tugas, title: values.title },
      });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihan = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihan' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihan = response.latihan;
      dispatch(latihanGet(latihan));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihanAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihanAnswer' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/answer/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihanAnswer = response.latihanAnswer;
      dispatch(latihanAnswerGet(latihanAnswer));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTes = (auth, history, value, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTes' });
    const {
      token,
      userId,
      nis,
      name,
      noabsen,
      active_kelas,
      active_year,
      active_sem,
      active_tingkat,
      active_jenjang,
    } = auth;
    const formData = new FormData();
    formData.append('token', token);
    formData.append('userId', userId);
    formData.append('nis', nis);
    formData.append('name', name);
    formData.append('noabsen', noabsen);
    formData.append('kelas', active_kelas);
    formData.append('year', active_year);
    formData.append('sem', active_sem);
    formData.append('tingkat', active_tingkat);
    formData.append('jenjang', active_jenjang);
    formData.append('tesStatus', JSON.stringify(value));
    formData.append('tesId', id);
    formData.append('auth', JSON.stringify(auth));
    
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        toastr.error('Error', `Data belum bisa diakses!`);
        history.push('/pembelajaran/tes');
        throw resultCheck;
      }
      const initialTes = response.tes;
      console.log(initialTes);
      
      let studentTes = response.studentTes;
      const tes = {
        ...initialTes,
        studentTes,
        isSubmit: studentTes && studentTes?.time ? true : false,
      };
      dispatch(tesGet({ item: tes, nis: auth.nis, studentTes }));
      let dateTime = new Date();
      const status = {
        ...JSON.parse(studentTes.status),
        id: studentTes.id,
        lastSeen: dateTime.toISOString(),
      };
      dispatch(
        navTesStatus({
          status,
          loadingStatus: false,
        })
      );

      let remarks = initialTes.remarks;
      if (remarks) dispatch(tesRemarksGet({ ...remarks }));

      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const setNavTesStatus = (auth, value) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'setNavTesStatus' });
    dispatch(navTesStatus({ status: value }));
    dispatch(asyncActionFinish());
  };
};

export const getTesAnswerKey = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugasAnswerKey' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('nis', auth.nis);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/answer-key/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        toastr.error('Error', `Data belum bisa diakses!`);
        history.push('/pembelajaran/tes');
        throw resultCheck;
      }
      const tes = { ...response.tes, submitted: response.submittedTes };
      dispatch(tesGet({ item: tes, nis: auth.nis }));
      dispatch(asyncActionFinish());
      if (response?.tes) {
        return 'finish';
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitTes = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitTes' });
    const remarks = {
      title: values.title,
      description: values.description,
      questions: values.questions,
      remarks: values.remarks,
    };
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('id', values.submittedId);
    formData.append('no', auth.noabsen);
    formData.append('nis', auth.nis);
    formData.append('siswa', auth.name ?? auth.username);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('kelas', auth.active_kelas);
    formData.append('tingkat', auth.active_tingkat);
    formData.append('jenjang', auth.active_jenjang);
    formData.append('tesId', values.id);
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('status', JSON.stringify(values.status));
    formData.append('time', values.time);
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', values.tags);
    formData.append('remarks', JSON.stringify(remarks));
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/submit/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tes = response.tes;
      dispatch(navTesStatus({ status: { tesStartStatus: false } }));
      history.push({
        pathname: '/pembelajaran/tes/submit/' + tes.tesId,
        state: { ...tes, title: values.title },
      });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const setTesStatus = (auth, history, values, updateActvity) => {
  // updateActivity: true or false
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'setTesStatus' });
    const formData = new FormData();
    const { token, userId } = auth;
    const { answers, ...restValues} = values;
    console.log(restValues);
    
    formData.append('userId', userId);
    formData.append('token', token);
    formData.append('id', restValues.id);
    formData.append('tesStatus', JSON.stringify(restValues));
    formData.append('tesId', restValues.tesId);
    formData.append('updateActivity', updateActvity);
    if (answers)
      formData.append('answers', JSON.stringify(answers));
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/set-status/' + restValues.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }

      const tes = response.tes;
      const studentTes = response.studentTes;
      const updateStatus =
        studentTes && studentTes.status && JSON.parse(studentTes.status);
      if (restValues.online && tes)
        dispatch(
          navTesStatus({
            status: { ...updateStatus },
            loadingStatus: false,
          })
        );
      dispatch(tesRemarksGet({ ...tes }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTesStatus = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTesStatus' });
    const formData = new FormData();
    formData.append('token', auth?.token);
    formData.append('userId', auth?.userId);
    formData.append('nis', auth?.nis);
    formData.append('tesId', id);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/get-status/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const status = response.status;
      const tesStatus = response.tesStatus;
      if (tesStatus && tesStatus.id)
        dispatch(navTesStatus({ status: { id: tesStatus.id } }));
      dispatch(asyncActionFinish());
      return status;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const trackLeavePage = (auth, history, timeLeavePage, student) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'trackLeavePage' });
    const formData = new FormData();
    formData.append('token', auth?.token);
    formData.append('userId', auth?.userId);
    formData.append('tesId', student.tesId);
    formData.append('nis', student.nis);
    formData.append('status', JSON.stringify(student));
    formData.append('unfocus', JSON.stringify(timeLeavePage));
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/tes/track-unfocus/' +
          student.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tesStatus = response.tesStatus;
      const status = JSON.parse(tesStatus.status);
      dispatch(navTesStatus({ status: { unfocus: status.unfocus } }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const setReadActivity = (auth, history, id, title, content) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'setReadActivity' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('username', auth.vusn);
    formData.append('noabsen', auth.noabsen);
    formData.append('siswa', auth.username);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('year', auth.active_year);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/set-read-activity/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const success = response.success;
      dispatch(asyncActionFinish());
      return success;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
// Web App Download
export const downloadFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/materi/download-file/' +
          data.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
// Android App Download
export const getAppLinkDownload = (auth, history, id, filelink) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'getAppLinkDownload',
    });
    const userId = auth.userId;
    const username = auth.username;
    const sessionId = auth.sessionId;
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('username', username);
    formData.append('filelink', filelink);

    try {
      const fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/materi/app-link-download/' +
          id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const linkRapor = response.url;
      dispatch(asyncActionFinish());
      return linkRapor;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
