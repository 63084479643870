import { PROFIL_GET, PROFIL_UPDATE } from './reduxConstant';
import { createReducer } from '../../../App/common/utilities/reducerUtils';

const initialState = {};

const profilGet = (state, payload) => {
  return { ...payload.profil };
};

const profilUpdate = (state, payload) => {
  return { ...state, ...payload.profil };
};

export default createReducer(initialState, {
  [PROFIL_GET]: profilGet,
  [PROFIL_UPDATE]: profilUpdate,
});
