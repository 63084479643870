import { ANNOUNCEMENTS_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = [];

const announcementsGet = (state, payload) => {
  return [...payload.announcements];
};

export default createReducer(initialState, {
  [ANNOUNCEMENTS_GET]: announcementsGet,
});
