import {
  asyncActionFinish,
  asyncActionError,
} from '../../../App/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../App/common/reduxs/async/asyncConstant';
import {
  SITE_ADDRESS,
  SITE_ADDRESS_PEMBELAJARAN,
} from '../../../App/common/data/siteConfig';
import {
  materisGet,
  tugassGet,
  latihansGet,
  tessGet,
  studentActivityGet,
} from '../../_features/pembelajaran/redux/reduxAction';
import { checkRes, checkErr } from '../../../App/common/helpers/checkRes';
import { unreadPesanGet } from './reduxAction';

export const getDashboard = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getDashboard' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : null;
    const deviceId = auth.deviceId ? auth.deviceId : null;
    console.log(sessionId, deviceId);
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'dashboard/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      console.log(SITE_ADDRESS + 'dashboard/');
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const dashboard = response.dashboard;
      console.log(dashboard);
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getPembelajaran = (auth, history, tingkat, kelas) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPembelajaran' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : null;
    const deviceId = auth.deviceId ? auth.deviceId : null;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/all-contents',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const totalMateri = response.totalMateri;
      const allMateri = response.allMateri;
      const totalTugas = response.totalTugas;
      const allTugas = response.allTugas;
      const totalLatihan = response.totalLatihan;
      const allLatihan = response.allLatihan;
      const totalTes = response.totalTes;
      const allTes = response.allTes;
      const studentActivity = response.studentActivity;
      dispatch(materisGet({ items: allMateri, total: totalMateri }));
      dispatch(tugassGet({ items: allTugas, total: totalTugas }));
      dispatch(latihansGet({ items: allLatihan, total: totalLatihan }));
      dispatch(tessGet({ items: allTes, total: totalTes }));
      dispatch(studentActivityGet(studentActivity));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getUnreadPesan = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getUnreadPesan' });
    if (auth && !auth.isAuth) {
      dispatch(asyncActionFinish());
      return;
    }
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : null;
    const deviceId = auth.deviceId ? auth.deviceId : null;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS_PEMBELAJARAN + 'pesan-pribadi/unread/' + userId, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const unreadPesan = response.unreadPesan;
      dispatch(unreadPesanGet(unreadPesan));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
