import { SLIDESHOWS_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = [];

const slideshowsGet = (state, payload) => {
  return [ ...payload.slideshows ];
};

export default createReducer(initialState, {
  [SLIDESHOWS_GET]: slideshowsGet,
});
