export const relationWithStudent = [
  { key: 1, text: 'Ayah', value: 'Ayah' },
  { key: 2, text: 'Ibu', value: 'Ibu' },
  { key: 3, text: 'Wali', value: 'Wali' },
];
export const activeStatus = [
  { key: 1, text: 'Aktif', value: true },
  { key: 2, text: 'Non Aktif', value: false },
];

export const gender = [
  { key: 1, text: 'Laki-laki', value: 'male' },
  { key: 2, text: 'Perempuan', value: 'female' },
];

export const religion = [
  { key: 1, text: 'Islam', value: 'Islam' },
  { key: 2, text: 'Kristen', value: 'Kristen' },
  { key: 3, text: 'Katholik', value: 'Katholik' },
  { key: 4, text: 'Hindu', value: 'Hindu' },
  { key: 5, text: 'Budha', value: 'Budha' },
  { key: 6, text: 'Konghucu', value: 'Konghucu' },
  { key: 7, text: 'Kepercayaan', value: 'Kepercayaan' },
  { key: 8, text: 'Lainnya...', value: 'Other' },
];

export const hobbies = [
  'Olahraga',
  'Musik',
  'Film',
  'Berwisata',
  'Membaca',
  'Bertualang',
  'Lainnya',
];

export const positions = [
  { key: 1, text: "Kepala Sekolah TK", value: "Kepala Sekolah TK" },
  { key: 2, text: "Kepala Sekolah SD", value: "Kepala Sekolah SD" },
  { key: 3, text: "Kepala Sekolah SMP", value: "Kepala Sekolah SMP" },
  { key: 4, text: "Kepala Sekolah SMA", value: "Kepala Sekolah SMA" },
];

export const teachers = [
  { key: 1, text: "Guru TK", value: "Guru TK" },
  { key: 2, text: "Guru SD", value: "Guru SD" },
  { key: 3, text: "Guru SMP", value: "Guru SMP" },
  { key: 4, text: "Guru SMA", value: "Guru SMA" },
];

export const NUM_ALPHABET = '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const colorNav = [
  { key: 1, text: 'Green 1', value: 'primary' },
  { key: 2, text: 'Green 2', value: 'success' },
  { key: 3, text: 'Blue 1', value: 'link' },
  { key: 4, text: 'Blue 2', value: 'info' },
  { key: 5, text: 'Yellow', value: 'warning' },
  { key: 6, text: 'Red', value: 'danger' },
  { key: 7, text: 'Black', value: 'black' },
  { key: 8, text: 'Dark', value: 'dark' },
  { key: 9, text: 'Light', value: 'light' },
  { key: 10, text: 'White', value: 'white' },
];

export const colorTextNav = [
  { key: 1, text: 'Green 1', value: 'primary' },
  { key: 2, text: 'Green 2', value: 'success' },
  { key: 3, text: 'Blue 1', value: 'link' },
  { key: 4, text: 'Blue 2', value: 'info' },
  { key: 5, text: 'Yellow', value: 'warning' },
  { key: 6, text: 'Red', value: 'danger' },
  { key: 7, text: 'Black', value: 'black' },
  { key: 8, text: 'Dark', value: 'dark' },
  { key: 9, text: 'Light', value: 'light' },
  { key: 10, text: 'White', value: 'white' },
];

export const colorActiveMenu = [
  { key: 1, text: 'Green 1', value: 'primary' },
  { key: 2, text: 'Green 2', value: 'primary-dark' },
  { key: 3, text: 'Green 3', value: 'success' },
  { key: 4, text: 'Green 4', value: 'success-dark' },
  { key: 5, text: 'Blue 1', value: 'link' },
  { key: 6, text: 'Blue 2', value: 'link-dark' },
  { key: 7, text: 'Blue 3', value: 'info' },
  { key: 8, text: 'Blue 4', value: 'info-dark' },
  { key: 9, text: 'Yellow 1', value: 'warning' },
  { key: 10, text: 'Yellow 2', value: 'warning-dark' },
  { key: 11, text: 'Red 1', value: 'danger' },
  { key: 12, text: 'Red 2', value: 'danger-dark' },
  { key: 13, text: 'Dark', value: 'dark' },
  { key: 14, text: 'Light', value: 'light' },
  { key: 15, text: 'Black 1', value: 'black' },
  { key: 16, text: 'Black 2', value: 'black-bis' },
  { key: 17, text: 'Grey 1', value: 'grey-darker' },
  { key: 18, text: 'Grey 2', value: 'grey-dark' },
  { key: 19, text: 'Grey 3', value: 'grey' },
];

export const colorActiveTextMenu = [
  { key: 1, text: 'Green 1', value: 'primary' },
  { key: 2, text: 'Green 2', value: 'success' },
  { key: 3, text: 'Blue 1', value: 'link' },
  { key: 4, text: 'Blue 2', value: 'info' },
  { key: 5, text: 'Yellow', value: 'warning' },
  { key: 6, text: 'Red', value: 'danger' },
  { key: 7, text: 'Dark', value: 'dark' },
  { key: 8, text: 'Black 1', value: 'black' },
  { key: 9, text: 'Black 2', value: 'black-bis' },
  { key: 10, text: 'Black 3', value: 'black-ter' },
  { key: 11, text: 'Grey 1', value: 'grey-darker' },
  { key: 12, text: 'Grey 2', value: 'grey-dark' },
  { key: 13, text: 'Grey 3', value: 'grey' },
  { key: 14, text: 'Grey 4', value: 'grey-light' },
  { key: 15, text: 'Grey 5', value: 'grey-lighter' },
];
