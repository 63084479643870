import { SLIDESHOWS_GET } from './reduxConstant';

export const slideshowsGet = (slideshows) => {
  return {
    type: SLIDESHOWS_GET,
    payload: {
      slideshows,
    },
  };
};
