import { RAPORMIDS_GET, RAPORMIDDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = { items: [] };

const rapormidsGet = (state, payload) => {
  let rapormids = payload.rapormids;
  if (!rapormids) return state;
  return { ...state, items: [...rapormids] };
};

const rapormidDetailGet = (state, payload) => {
  if (!payload.rapormidDetail) return state;
  let rapormidDetail = {
    ...state.items.filter(
      (item) => item.IDXRAPOR === payload.rapormidDetail?.id
    )[0],
    ...payload.rapormidDetail,
  };
  return {
    ...state,
    items: [
      ...state?.items?.filter(
        (item) => item.IDXRAPOR !== payload.rapormidDetail?.id
      ),
      rapormidDetail,
    ],
  };
};

export default createReducer(initialState, {
  [RAPORMIDS_GET]: rapormidsGet,
  [RAPORMIDDETAIL_GET]: rapormidDetailGet,
});
