import React, { useState } from 'react';
import AccountGroup from '../../common/icons/AccountGroup';

const LoginSelectInput = ({
  input,
  label,
  type,
  icon,
  placeholder,
  multiple,
  options,
  fullwidth,
  disabled,
  readOnly,
  style,
  meta: { touched, error, active },
}) => {
    const [selectInput, setIsSelectInput] = useState(false);

  return (
    <div className='field' onBlur={() => setIsSelectInput(false)}
    onFocus={() => setIsSelectInput(true)}>
      <label className='label is-size-7' htmlFor={input.name}>
        {label}
      </label>
      <div
        className={
          touched && !!error
            ? 'control is-danger is-expanded has-icons-left'
            : 'control is-expanded has-icons-left'
        }
      >
        <div
          className={
            fullwidth === true
              ? 'select is-fullwidth is-small is-rounded'
              : 'select is-small is-rounded'
          }
        >
          <select
            id={input.name}
            value={input.value}
            onChange={input.onChange}
            placeholder={placeholder}
            multiple={multiple}
            disabled={readOnly}
            style={style}
          >
            <option value=''>Pilih {placeholder}</option>
            {options &&
              options.map((item) => (
                <option key={item.key} value={item.value} style={style}>
                  {item.text}
                </option>
              ))}
          </select>
        </div>
        <div className='icon is-small is-left'>
          {icon === 'account-group' && (
            <AccountGroup size={12} color={selectInput ? '#363636' : '#c2c2c2'} />
          )}
        </div>
        {touched && error && <p className='help is-danger'>{error}</p>}
      </div>
    </div>
  );
};

export default LoginSelectInput;
