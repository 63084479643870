import React from 'react';
import Lock from '../../common/icons/Lock';
import Account from '../icons/Account';

const LoginTextInput = ({
  input,
  width,
  type,
  icon,
  disabled,
  readOnly,
  placeholder,
  label,
  meta: { touched, error, active },
}) => {
  return (
    <div className='field'>
      <label className='label is-size-7 has-text-left'>{label}</label>
      <div className='control has-icons-left'>
        <input
          {...input}
          className={
            touched && !!error
              ? 'input is-danger is-small is-rounded'
              : 'input is-small is-rounded'
          }
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span className='icon is-small is-left'>
          {icon === 'account' && (
            <Account size={12} color={active ? '#363636' : '#c2c2c2'} />
          )}
          {icon === 'lock' && (
            <Lock size={12} color={active ? '#363636' : '#c2c2c2'} />
          )}
        </span>
      </div>
      {touched && error && <p className='help is-danger has-text-left'>{error}</p>}
    </div>
  );
};

export default LoginTextInput;
