import React, { useEffect } from 'react';
import { withRouter, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionLogout, getRoleAuth } from './redux/authApi';
import SectionBody from './part/SectionBody';
import Herobody from './part/Herobody';
import ContainerBody from './part/ContainerBody';
import Alert from '../common/icons/Alert';
import LoginIcon from '../common/icons/LoginIcon';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  sessionLogout,
  getRoleAuth,
};

function NotFound(props) {
  const { sessionLogout, auth, getRoleAuth } = props;
  let history = useHistory();

  useEffect(() => {
    getRoleAuth(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleAuth]);

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;

  return (
    <SectionBody>
      <Herobody>
        <ContainerBody>
          <div className='media'>
            <div className='media-content'>
              <div className='content'>
                <div id='fadein2' className='has-text-centered mt-3 mb-3'>
                  <Alert size={36} color={'#f14668'} />
                </div>
                <div className='is-size-6 has-text-centered is-italic mt-1'>
                  Halaman tidak ditemukan!
                </div>
                {!auth.isAuth && (
                  <div className='has-text-info is-size-5 has-text-centered is-italic hand-pointer mt-3'>
                    <div
                      className='button is-info is-small is-rounded is-outlined'
                      onClick={() => handleSessionLogout('logoutNav')}
                    >
                      <LoginIcon size={15} color={'#3e8ed0'} />{' '}
                      <p className='ml-1'>Login</p>
                    </div>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainerBody>
      </Herobody>
    </SectionBody>
  );
}

export default withRouter(connect(mapState, actions)(NotFound));
