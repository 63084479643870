import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import LoginTextInput from '../common/form/LoginTextInput';
import { newLoginAuth } from './redux/authApi';
import { useUnamePass } from '../common/helpers/othersHelpers';
import LoginIcon from '../common/icons/LoginIcon';
import BodyAuth from './part/BodyAuth';
import RegisterParents from './part/RegisterParents';
import LoadingButton from '../common/layout/LoadingButton';
import Herobody from './part/Herobody';
import SectionBody from './part/SectionBody';
import { useWindowSize } from '../common/helpers/sizeHelper';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  newLoginAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username is required' }),
  password: isRequired({ message: 'Password is required' }),
});

function Login(props) {
  const { newLoginAuth, handleSubmit, auth, error, loading } = props;
  let uPass = useUnamePass();

  let windowSize = useWindowSize();

  const handleLogin = (authData) => {
    newLoginAuth(authData);
  };

  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;
  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;

  if (uPass)
    return (
      <SectionBody>
        <Herobody
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <LoadingButton />
        </Herobody>
      </SectionBody>
    );

  return (
    <BodyAuth
      title={'Selamat Datang di Portal Sekolah'}
      width={
        windowSize[0] < 400 ? (windowSize[0] > 365 ? 365 : windowSize[0]) : 400
      }
    >
      <form onSubmit={handleSubmit(handleLogin)} autoComplete='off'>
        <Field
          name='username'
          type='text'
          component={LoginTextInput}
          placeholder='Username'
          autoComplete='new-password'
          label='Username'
          icon='account'
        />
        <Field
          name='password'
          type='password'
          autoComplete='new-password'
          component={LoginTextInput}
          placeholder='Password'
          label='Password'
          icon='lock'
        />
        <div className='field has-text-centered' style={{ marginTop: 25 }}>
          {error && <p className='help is-danger'>{error}</p>}
          <button
            disabled={loading}
            type='submit'
            style={{ marginRight: 10 }}
            className={`button is-info is-small is-rounded${
              loading ? ' is-loading' : ''
            }`}
          >
            <span className='mr-1 mt-1 flex justify-start align-center'>
              {!loading && <LoginIcon size={15} color={'#ffffff'} />}
              <span className='ml-1'>Login</span>
            </span>
          </button>
        </div>
      </form>
      <RegisterParents />
    </BodyAuth>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'formLogin', validate })(Login))
);
