import { authLogout } from '../../login/redux/authAction';
import { Menus } from '../../common/data/menus';

export const checkAuthScope = (openModal, authDetail, pathname) => {
  return (dispatch) => {
    const url = pathname.split('/').filter((n) => n);
    const menu = Menus.find((item) => item.url.split('/').join('') === url[0]);
    let key = menu && menu.id;
    const accessM = authDetail.m[key + '_view'];
    if (!accessM) {
      dispatch(openModal('NotAuthorizedModal'));
    }
  };
};

export const checkSession = (history) => {
  return (dispatch) => {
    dispatch(authLogout());
    history.push('/');
  };
};

export const authUpdating = (usrRoles, allRoles) => {
  // user Role = Siswa, userId = 1586
  let authorities;
  let title = [];
  let authM = {};
  let authSubm = {};
  if (['Siswa', 'Ayah', 'Ibu', 'Wali'].includes(usrRoles)) {
    const menus = [...Menus];
    menus.forEach((m) => {
      authM = { ...authM, ...m.auth };
      if (m.subm.length > 0) {
        m.subm.forEach((subm) => {
          authSubm = { ...authSubm, ...subm.auth };
        });
      }
    });

    Object.keys(authM).forEach((key) => {
      authM[key] = true;
    });

    Object.keys(authSubm).forEach((key) => {
      authSubm[key] = true;
    });

    title = [usrRoles];

    authorities = {
      title,
      m: authM,
      subm: authSubm,
    };
  } else if (usrRoles === null || (usrRoles && usrRoles.length < 1)) {
    authM = {
      dashboard_view: true,
      profil_view: true,
    };
    authorities = {
      title,
      m: authM,
      subm: authSubm,
    };
  } else if (!allRoles) {
    authM = {
      dashboard_view: true,
      profil_view: true,
    };
    authorities = {
      title,
      m: authM,
      subm: authSubm,
    };
  } else {
    title = usrRoles && usrRoles.split(',');
    let mergeM = {};
    let mergeSubm = {};
    for (let i = 0; i < title.length; i++) {
      for (let j = 0; j < allRoles.length; j++) {
        if (allRoles[j].id + '' === title[i]) {
          const dataAuth = JSON.parse(allRoles[j].authorities);
          mergeM = { ...mergeM, ...dataAuth.m };
          mergeSubm = { ...mergeSubm, ...dataAuth.subm };
        }
      }
    }
    authorities = {
      title,
      m: mergeM,
      subm: mergeSubm,
    };
  }
  return authorities;
};
