import React, { useEffect, useState } from 'react';

function ModalTesLeavePageButtonBack(props) {
  const { run, onClose } = props;
  const [counter, setCounter] = useState(9);

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      if (run) {
        setCounter((prevState) => {
          return prevState - 1;
        });
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [run, counter]);

  return (
    <button
      onClick={onClose}
      disabled={counter > 0}
      className='button is-info is-small is-rounded is-outlined'
    >
      {counter > 0 ? `Kembali (${counter})` : 'Kembali'}
    </button>
  );
}

export default ModalTesLeavePageButtonBack;
