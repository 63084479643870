import React from 'react';
import Copyright from './Copyright';
import SectionBody from './SectionBody';
import Herobody from './Herobody';
import ContainerBody from './ContainerBody';
import SchoolTitle from './SchoolTitle';

export default function BodyAuth({ children, title, width }) {
  return (
    <SectionBody>
      <Herobody>
        <ContainerBody width={width}>
          <SchoolTitle title={title} />
          {children}
        </ContainerBody>
      </Herobody>
      <Copyright />
    </SectionBody>
  );
}
